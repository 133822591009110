import React from "react";
import { useTranslation } from "react-i18next";



const HomeCapabilities = () => {
  const { t } = useTranslation();

  return (
    <>

{/* <div className={`single-slider slider__height d-flex align-items-center home_slider_3`}> */}

      {/* <section className="capabilities__area p-relative black-bg-2 pt-100 pb-115 fix home_slider_4"> */}
      <section className="capabilities__area p-relative black-bg-2 pt-200 pb-200 fix" style={{ backgroundColor: '#7c74ff' ,backgroundPosition: 'center', backgroundSize: 'cover' }}>

      


        <div
          className="capabilities__thumb p-absolute"
          style={{
            background: `url(assets/img/capabilities/capabilities-img.svg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>

          {/* 
          <img
          // className="shape dotted-square"
          src="assets/img/capabilities/capabilities-img.svg"
          alt="theKey" /> 
          */}


          {/* 
          <div className="capabilities__shape p-absolute wow fadeInLeft">
            <img
              src="assets/img/capabilities/capabilities-shape2.png"
              alt="shape"
            />
          </div> 
          */}


        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div
                className="capabilities__content wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="section__title section__title-2 mb-60">
                  <span className="white-color">{t("home_capabilities_title_mini")}</span>
                  <h2 className="white-color">
                  {t("home_capabilities_title_big")}
                  </h2>
                </div>
                <div className="capabilities__list">
                  <p className="white-color" style={{ fontSize: "17px" }}>
                  {t("home_capabilities_subtitle")}
                  </p>

                  <ul className="white-color">
                    <li>{t("home_capabilities_caption_one")}</li>
                    <li>{t("home_capabilities_caption_two")}</li>
                    <li>{t("home_capabilities_caption_three")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCapabilities;
