import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import ContactArea from './ContactArea/ContactArea';
import ContactInfoArea from './ContactInfoArea/ContactInfoArea';
import { useTranslation } from "react-i18next";





const Contact = () => {
  const { t } = useTranslation();

   return (
      <>
         <PageHelmet pageTitle="Contact Page" />
         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_contact_us")} subtitle={t("page_title_contact_us")} />
         {/* <ContactInfoArea/> */}
         <ContactArea/>
         <Footer/>
      </>
   );
};

export default Contact;