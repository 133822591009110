import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { useTranslation } from "react-i18next";



const NewsletterPolicies = () => {
  const { t } = useTranslation();


   return (
      <>
         <PageHelmet pageTitle="Policies" />

         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_policies")} subtitle={t("page_title_policies")} />

         
            <section className="price__area pt-115 pb-75">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="section__title section__title-3 mb-85 text-center">
                                <span>{t("policies_title_mini_newsletter")}</span>
                                <h2>{t("policies_newsletter_title")}</h2>
                            </div>
                            <div className="text-center">
                                
                                <br/>
                                <h3>{t("policies_newsletter_title_one")}</h3>
                                <br/>
                                <p>{t("policies_newsletter_description_one")}</p>
                                
                                <br/>
                                <h4>{t("policies_newsletter_title_two")}</h4>
                                <p>{t("policies_newsletter_description_two")}</p>
                                  
                                <br/>
                                <h4>{t("policies_newsletter_title_three")}</h4>
                                <p>{t("policies_newsletter_description_three")}</p>
                                  
                                <br/>
                                <h4>{t("policies_newsletter_title_four")}</h4>
                                <p>{t("policies_newsletter_description_four")}</p>
                               
                                <br/>
                                <h4>{t("policies_newsletter_title_five")}</h4>
                                <p>{t("policies_newsletter_description_five")}</p>
                                
                                <br/>
                                <h4>{t("policies_newsletter_title_six")}</h4>
                                <p>{t("policies_newsletter_description_six")}<b> letstalk@invokeapp.io</b></p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        

         {/* <HomeServices />
         <HomeCapabilities/>
         <HomeThreeSecondServices /> */}


         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default NewsletterPolicies;