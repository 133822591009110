import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { businessLogic } from '../../../lib/constants';
import { useTranslation } from "react-i18next";



const BlogFooter = () => {
   const { t } = useTranslation();


   return (
      <>
         <footer>
            <div className="footer__area black-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/home">
                                       <img src={`assets/img/logo/${businessLogic.logoNegative}`} alt="" height={40}/>
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content footer__widget-content-2">
                                 <p className="mb-30">{businessLogic.copyright}</p>
                                 <div className="footer__social footer__social-2 theme-social mb-30">
                                    <ul>

                                    <li>
                                       <a href={businessLogic.linkedin} target="blank">
                                             <i ><FaLinkedin color='#7c74ff'/> </i>
                                             <i ><FaLinkedin /> </i>
                                          </a>
                                       </li>

                                       <li>
                                       <a href={businessLogic.instagram} target="blank">
                                             <i ><FaInstagram color='#7c74ff'/> </i>
                                             <i ><FaInstagram /> </i>
                                          </a>
                                       </li>

                                       <li>
                                       <a href={businessLogic.facebook} target="blank">
                                             <i ><FaFacebookF color='#7c74ff'/></i>
                                             <i ><FaFacebookF /></i>
                                          </a>
                                       </li>
                             

                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title text-white">
                                 <h4>{t("home_footer_title_company")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                 <ul>
                                       <li><a href="/about">{t("home_footer_company_about_us")}</a></li>
                                       <li><a href="/home#services">{t("home_footer_company_services")}</a></li>
                                       <li><a href={businessLogic.consulting_landing} target='blank'>{t("home_footer_company_consulting")}</a></li>
                                       <li><a href="/blogs">{t("home_footer_company_blog")}</a></li>
                                       <li><a href="/contact#contact">{t("home_footer_company_contactus")}</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title text-white">
                                 <h4>{t("home_footer_title_resources")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                 <ul>
                                       {/* <li><a href="#">Customers</a></li> */}
                                       <li><a href="/home#logo_customers">{t("home_footer_resources_customers")}</a></li>
                                       <li><Link to="/pricing">{t("home_footer_resources_pricing")}</Link></li>
                                       <li><a href="/blogs">{t("home_footer_resources_news")}</a></li>
                                       {/* <li><a href="#">Learning Center</a></li> */}
                                       {/* <li><a href="#">Help desk</a></li> */}
                                       <li><a href="/contact#contact">{t("home_footer_resources_support")}</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title text-white">
                                 <h4>{t("home_footer_title_usecases")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                 <ul>
                                       <li><a href={businessLogic.uiux_landing} target='blank'>{t("home_footer_usecases_uiuxdesign")}</a></li>
                                       <li><a href={businessLogic.mobile_landing} target='blank'>{t("home_footer_usecases_mobileapp")}</a></li>
                                       {/* <li><a href={businessLogic.backend_landing} target='blank'>Backend Optimization</a></li> */}
                                       {/* <li><a href="#">SEO for Business</a></li> */}
                                       <li><a href={businessLogic.backend_landing} target='blank'>{t("home_footer_usecases_apiintegration")}</a></li>
                                       <li><a href={businessLogic.database_landing} target='blank'>{t("home_footer_usecases_database")}</a></li>
                                       <li><a href={businessLogic.saas_landing} target='blank'>{t("home_footer_usecases_software")}</a></li>
                                       <li><a href={businessLogic.data_landing} target='blank'>{t("home_footer_usecases_analytics")}</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


               <div className="footer__copyright footer__copyright-2">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-12 text-center">
                           <div className="footer__copyright-text footer__copyright-text-2">
                              <p>{businessLogic.copyright}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </footer>
      </>
   );
};

export default BlogFooter;