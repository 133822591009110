import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import SingleBrandItem from "../../../components/SingleBrandItem/SingleBrandItem";

import { constants } from "../../../lib/constants";
import LoaderSpiner from "../../../components/LoadersSpiner/loader";
import { useTranslation } from "react-i18next";


const HomeBrands = () => {
   const { t } = useTranslation();

  let getLogo = constants.getCustomersLogo;
  const [customers_logo, setLogo] = useState([]);


  useEffect(() => {
   fetch(getLogo)
     .then((res) => res.json())
     .then((data) => setLogo(data))
     .catch((e) => console.log(e));
 }, []);




   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 10000,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               infinite: true,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            }
         },
      ]
   };

   
   return (
      <>
         <section className="brand__area p-relative pt-160 pb-150" id="logo_customers">
            <div className="brand__shape">
               <img className="square" src="assets/img/icon/brand/square.svg" alt="" />
               <img className="circle" src="assets/img/icon/brand/circle.svg" alt="" />
               <img className="circle-2" src="assets/img/icon/brand/circle-2.svg" alt="" />
               <img className="triangle" src="assets/img/icon/brand/triangle.svg" alt="" />
            </div>
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-4 offset-xl-1 col-lg-4 offset-lg-1">
                     <div className="section__title mb-30">
                        <span>{t("home_brands_customers_title_mini")}</span>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="section__title section__title-3 wow fadeInUp" data-wow-delay=".2s">
                     <h2>{t("home_brands_customers_title_big")}</h2>
                     </div>
                     <div className="brand__subtitle mb-90 wow fadeInUp" data-wow-delay=".4s">
                        <p>{t("home_brands_customers_subtitle")}</p>
                     </div>
                  </div>
               </div>
               <div className="row">








                  {customers_logo.length > 0 ? (
                        <Slider className='brand-active' {...settings}>
                           {customers_logo.map((logo, index) => (
                              index % 2 === 0 && (
                              <SingleBrandItem
                                 key={index}
                                 data={[logo, customers_logo[index + 1]]}
                                 columns={2}
                              />
                              )
                           ))}
                        </Slider>
                        ) : (
                        <LoaderSpiner />
                        )}
      


                  





               </div>
            </div>
         </section>

      </>
   );
};

export default HomeBrands;