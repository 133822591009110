import React from "react";
import { BiFontSize } from "react-icons/bi";
import { Link } from "react-router-dom";

import { Image } from "react-bootstrap";


const SingleService = ({ destiny, icon, title, description }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
        <a href={destiny} target="blank">
          <div className="services__item mb-90">
            <div className="services__icon mb-35">

              <img
                src={`assets/img/icon/services/service-${icon}.png`}
                alt="services"
                height={65}
              />

              {/* <Image src={`assets/img/icon/services/services-${icon}.png`}/> */}

            </div>
            <div className="services__content">
              <h3>
                {/* <Link to="/servicesDetails"> */}
                {title}
                {/* </Link> */}
              </h3>
              <p style={{ fontSize: 16 }}>{description}</p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SingleService;
