import React, {  useState } from 'react';

import { constants } from '../../../lib/constants';

import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



export default function PrelaunchForm(){
    const { t } = useTranslation();

    let createUserPrelaunch = constants.createUserPrelaunch;

    const [email, setEmail] = useState('');
    const [politics, setPolitics] = useState(false);


    
  const resetForm = () => {
    setEmail("");
    setPolitics("");
  };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!politics) {
            toast.error(t("generic_newsletter_toast_error_politics"));
            return;
        }

        const formData = {
            email,
            politics,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
        };

        fetch(createUserPrelaunch, requestOptions)
            .then((response) => {

                console.log(response)
                console.log(response.status)
                console.log(response.body)

                if (response.status === 201) {
                    toast.success(t("generic_toast_success_send_message"));
                    resetForm();
                } else {
                    toast.error(t("generic_toast_error_notsend_message"));
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    };

            
                return(
                    <div className="col-xl-10 col-lg-3 col-md-5 col-sm-6">
                        <form id="newsletterForm" onSubmit={ handleSubmit } >

                            <div className="footer__widget mb-45">
                                
                                
                                {/* <div className="sidebar__widget-title mb-50">
                                    <h4>{t("blog_newsletter_title")}</h4>
                                </div> */}

                                {/* <div className="footer__widget-content">
                                    <div className="footer__subscribe">
                                    <p className="mb-20">{t("blog_newsletter_subtitle")}</p>                      
                                    </div>
                                </div> */}

                                
                                
                                
                                
                                <div className="footer__widget-content">
                                    <div className="footer__subscribe">
                                        <div className="footer__subscribe-form p-relative">
                                            <input type="email" name="email" placeholder={t("blog_newsletter_email_adress_input")} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                            <button type="submit" id="subscribeButton">{t("core_coreprelaunch_button_text")}</button>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div class="checkbox-container">
                                    <input type="checkbox" id="acceptPolicy" name="acceptPolicy" checked={politics} onChange={(e) => setPolitics(e.target.checked)} required />
                                    <label >&nbsp;&nbsp;&nbsp;{t("blog_newsletter_policy_one")}<a href="/newsletter-policies">{t("blog_newsletter_policy_two")}</a></label>
                                    {/* for="acceptPolicy" */}
                                </div>
                                
                                


                            </div>
                        </form>
                        <ToastContainer />

                    </div>
    )

}
