import React from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';


const PageHelmet = ({ pageTitle }) => {
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.pageview(window.location.pathname);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle} || InvokeApp </title>
                <meta name="robots" content="follow" />
                <meta name="description" content="InvokeApp Consulting & IT Services" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
        </>
    );
};

export default PageHelmet;