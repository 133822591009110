




const BASE_API = "https://api.invokeapp.io/api/v1/"
const CORE_API = "https://dev.core.invokeapp.io/api/v1/"

// const BASE_API = "http://localhost:8000/api/v1/"
// const BASE_API = "http://127.0.0.1:8000/api/v1/"
// const CORE_API = "http://127.0.0.1:8000/api/v1/"


export const constants = {
    // signUp: BASE_API+"",
    // signIn: BASE_API+"auth/token/",
    // contactUs: BASE_API+"contact/us",

    getThreeArticles: BASE_API+"blog/threearticles/",
    getLastArticles: BASE_API+"blog/articles/",
    getUniqueArticle: BASE_API+"blog/article/",
    getTags: BASE_API+"tags/getall",

    postCommentBlog: BASE_API+"blog/create_comment/",
    getSingleComment: BASE_API+"blog/get_single_comment/",
    getThreeComments: BASE_API+"blog/threecomments",

    getProjects: BASE_API+"projects/getworks",
    getCustomersLogo: BASE_API+"projects/getlogocustomers",

    createUserNewsletter: BASE_API+"blog/create-user-newsletter",



    createUserPrelaunch: CORE_API+"settings/create-user-prelaunch",
    contactUs: CORE_API+"settings/contact-us",

}




export const colors = {
    mainPurple: "#7C74FF",
    // mainGrey: "#D8DBF1"
    mainGrey: "#E8E8E8"
}


export const businessLogic = {
    emailBase: "letstalk@invokeapp.io",
    // address: "Madeira NorthEast Drive, Suite 506 Albuquerque, NM 19004",
    // address: "120 Madeira Drive Northeast, STE 220 Albuquerque, NM, 87108 United States",
    address: "Madrid (Spain)",
    // phoneNumber: "(404) 888 123 456",
    phoneNumber: "",
    // linkedin: "https://www.linkedin.com/in/invoke-app-85a212266/",
    linkedin: "https://www.linkedin.com/company/invoke-app-consulting-it-services/",
    instagram:"https://www.instagram.com/invokeapp/",
    facebook:"https://www.facebook.com/invokeapp",
    copyright: "Copyright © 2022 Invoke App",
    // copyright: "Copyright © 2022 Invoke App Consulting & IT Services LLC",
    logoBlack: "logo-black.png",
    // logoBlack: "logo-ia-black.png",
    logoWhite: "logo-white.svg",
    logoCian: "logo-cian.svg",
    logoNegative: "logo-negative.svg",
    // logoWhite: "logo-ia-white.png"

    mobile_landing: "https://appforge.invokeapp.io",
    backend_landing: "https://stackforge.invokeapp.io",
    consulting_landing: "https://techconsult.invokeapp.io",
    database_landing: "https://datamasters.invokeapp.io",
    data_landing: "https://datainsights.invokeapp.io",
    uiux_landing: "https://designhub.invokeapp.io",
    webapps_landing: "https://appignite.invokeapp.io",
    saas_landing: "https://saasguru.invokeapp.io"

}
