import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
          navbar_home: "Home",
          navbar_about_us: "About Us",
          navbar_services: "Solutions",
          navbar_portfolio: "Functionalities",
          navbar_resources: "Resources",
          navbar_blog: "Blog",
          contact_us: "Let's Talk",
          cookies_text: "We use cookies to enhance your experience on our website. By continuing to browse this site, you agree to our use of cookies. These cookies allow us to offer you a personalized experience, display relevant advertising, and obtain usage statistics of the site. For more information on the use of cookies and how to manage them, please see our Cookie Policy. By clicking 'Accept All', you consent to the use of all cookies.",
          cookies_button: "Accept All",
    
          page_title_about_us: "Empowering Creators",
          page_title_services: "Smart Solutions",
          page_title_portfolio: "Our Skills",
          page_title_resources: "Your Toolkit",
          page_title_blog: "Latest Insights",
          page_title_blog_details: "Insight Details",
          page_title_policies: "Policies",
          page_title_contact_us: "Reach Out",
    
          home_slider_title_superior_one: "Boost Your Efficiency",
          home_slider_title_superior_two: "Seamless Solutions for Infopreneurs",
          home_slider_title_main_copy_one1: "Harness the power of integration ",
          home_slider_title_main_copy_one2: "to streamline your digital world",
          home_slider_title_main_copy_two1: "Leverage automation for ",
          home_slider_title_main_copy_two2: "efficiency, speed, and scalability",


          core_coreprelaunch_title_mini: "core.invokeapp",
          core_coreprelaunch_title_slogan: "ERP for visionaries",
          core_coreprelaunch_description: "Sign up for the pre-launch and be one of the privileged to launch your business as an early adopter!",
          core_coreprelaunch_button_text: "Join up",
          core_coreprelaunch_title_more_info: "Information",
          core_coreprelaunch_description1_more_info: "Our ERP system revolutionizes enterprise management by integrating with key third-party platforms, allowing for seamless automation and data synchronization. It features robust modules for analytics, financial control, and company management, including employee onboarding and role assignment. A dedicated learning center supports entrepreneurial growth with essential resources. With powerful automation capabilities, the system significantly reduces manual work, enhancing efficiency and providing comprehensive insights for strategic decision-making. This all-encompassing tool offers businesses a powerful, tentacular solution to navigate and thrive in the competitive market landscape.",
          core_coreprelaunch_description2_more_info: "Join our team and elevate your project with our advanced ERP system. Harness the power of seamless integrations and strategic automation to transform your business operations and achieve new heights of success.",
          core_coreprelaunch_button_more_information: "More Information?",
          


          home_two_features_title_superior_mini: "Unique Solutions",
          home_two_features_title_one: "Transform ",
          home_two_features_title_two: "your business",
          home_two_features_subtitle: "Explore new horizons with automations that unleash your potential. Dive into a world where every second counts and every lead is a golden opportunity.",
          home_two_features_button_text: "Join the pre-launch",
          home_two_features_card_title_one: "Time Maximization",
          home_two_features_card_description_one: "Reinvent time management with automations that simplify tasks and give you back valuable hours.",
          home_two_features_card_title_two: "Operational Efficiency",
          home_two_features_card_description_two: "Optimize every process and reduce costs, transforming your operation into a model of efficiency and sustainability.",
          home_two_features_card_title_three: "Customer Retention",
          home_two_features_card_description_three: "Strengthen ties with your customers through meaningful and personalized interactions, powered by AI.",
          home_two_features_card_title_four: "Lead Conversion",
          home_two_features_card_description_four: "Maximize your sales funnel with automatic follow-up strategies that convert more leads into satisfied customers.",
        

          home_about_title_mini: "Our Essence",
          home_about_title_big: "Simplifying Your Success",
          home_about_subtitle: "Dive into a world where technology serves creativity. At Invoke App, we specialize in crafting seamless integrations and automations that empower your digital presence and optimize your workflow.",
          home_about_caption_one: "Effortless Integrations",
          home_about_caption_two: "Automation Mastery",
          home_about_caption_three: "Creative Freedom",
          home_about_discover_services: "Explore Possibilities",
    
          home_capabilities_title_mini: "Our Vision",
          home_capabilities_title_big: "Bridging Gaps with Tech",
          home_capabilities_subtitle: "From the spark of an idea to execution, we focus on delivering solutions that not only work but work wonders for you. Discover how our expertise in integration and automation can transform your business.",
          home_capabilities_caption_one: "- Idea to Execution",
          home_capabilities_caption_two: "- Design to Launch",
          home_capabilities_caption_three: "- Testing to Triumph",
    
          home_services_title_mini: "Transformations",
          home_services_title_big: "Elevate Your Workflow",
          home_services_title_mobileapps: "Integrated Platforms",
          home_services_subtitle_mobileapps: "Craft solutions that connect your content to your audience, with seamless app experiences.",
          home_services_title_backend: "Automated Systems",
          home_services_subtitle_backend: "Ensure your backend is as smart as your ideas, with systems that talk to each other and automate your processes.",
          home_services_title_consulting: "Strategic Insights",
          home_services_subtitle_consulting: "Get ahead with our consultancy, focusing on automation and integration strategies that scale.",
          home_services_title_database: "Data at Your Fingertips",
          home_services_subtitle_database: "Organize, access, and utilize your data with systems designed for efficiency.",
          home_services_title_analytics: "Insights into Action",
          home_services_subtitle_analytics: "Turn data into decisions with analytics that highlight opportunities for automation and efficiency.",
          home_services_title_uiuxdesign: "Engaging Experiences",
          home_services_subtitle_uiuxdesign: "Design that understands your user's journey, optimized for interaction and conversion.",
          home_services_title_webapps: "Seamless Web Apps",
          home_services_subtitle_webapps: "Web applications that are powerful yet intuitive, bridging your services with your audience.",
          home_services_title_saas: "SaaS Integration",
          home_services_subtitle_saas: "Extend your service's reach and capabilities with integrations that bring your tools together.",
    
          home_contact_area_title_mini: "Let's Innovate",
          home_contact_area_title_big: "Questions? Ideas? Collaborate with Us",
    
          home_brands_customers_title_mini: "Partners in Success",
          home_brands_customers_title_big: "Empowering 100+ platforms",
          home_brands_customers_subtitle: "Your vision, our technology. Together, we create digital experiences that make a difference.",
    
          home_contact_title: "Connect with Us",
          home_contact_address: "Where Ideas Meet Solutions",
          home_contact_email: "Talk to Us",
    
          home_footer_title_company: "Navigate",
          home_footer_company_about_us: "Discover",
          home_footer_company_services: "Solutions",
          home_footer_company_consulting: "Insights",
          home_footer_company_blog: "Blog",
          home_footer_company_policies:"Policies",
          home_footer_company_contactus: "Connect",
          home_footer_title_resources: "Toolkit",
          home_footer_resources_customers: "Case Studies",
          home_footer_resources_pricing: "Value",
          home_footer_resources_news: "Updates",
          home_footer_resources_support: "Support",
          home_footer_title_usecases: "How-To",
          home_footer_usecases_uiuxdesign: "Design Thinking",
          home_footer_usecases_mobileapp: "App Integration",
          home_footer_usecases_apiintegration: "API Magic",
          home_footer_usecases_database: "Data Dynamics",
          home_footer_usecases_software: "Software Synergy",
          home_footer_usecases_analytics: "Analytics Insights",
          home_footer_title_information: "Learn More",



          about_us_two_faq_title_mini: "Insights",
          about_us_two_faq_title_big_one: "Tech Expertise",
          about_us_two_faq_title_big_two: "for Seamless Integration",
          about_us_two_faq_function_one_title: "Simplifying Complex Concepts",
          about_us_two_faq_function_one_content: "Making intricate tech concepts accessible, because innovation thrives on simplicity.",
          about_us_two_faq_function_two_title: "Boosted Digital Performance",
          about_us_two_faq_function_two_content: "Optimizing digital platforms for peak performance, ensuring speed, efficiency, and satisfaction.",
          about_us_two_faq_function_three_title: "Integrated Solutions",
          about_us_two_faq_function_three_content: "Connecting systems and tools effortlessly to enhance efficiency and teamwork, propelling your projects forward.",
        
          about_us_two_achievement_title_mini: "Achievements",
          about_us_two_achievement_title_big: "Championing Technical Excellence",
          about_us_two_achievement_subtitle: "Drive innovation and excellence with us. Elevating projects and client satisfaction to new heights.",
          about_us_two_achievement_function_one: "Seamless Integrations",
          about_us_two_achievement_function_two: "Cutting-edge Mobile App Solutions",
          about_us_two_achievement_discover_more: "Explore More",
        
          services_three_second_services_title: "Our Expertise",
          services_three_second_services_subtitle: "Guiding you through today's challenges with our top-tier team.",
        
          services_three_second_services_section_one_title_superior: "Mobile Apps",
          services_three_second_services_section_one_title: "Mobile Apps",
          services_three_second_services_section_one_subtitle: "Turning ideas into engaging, user-focused mobile apps. Delivering standout experiences across iOS and Android, blending functionality with beauty.",
          services_three_second_services_section_one_power_phrase: "Crafting Immersive App Journeys",
        
          services_three_second_services_section_two_title_superior: "Backend & Integrations",
          services_three_second_services_section_two_title: "Backend & Integrations",
          services_three_second_services_section_two_subtitle: "Developing strong, scalable backends. Mastering data, user interactions, and smooth third-party service integrations for a comprehensive experience.",
          services_three_second_services_section_two_power_phrase: "Designing Unified Backend Ecosystems",
        
          services_three_second_services_section_three_title_superior: "Tech Consulting",
          services_three_second_services_section_three_title: "Tech Consulting",
          services_three_second_services_section_three_subtitle: "Navigating the tech landscape with strategic insights and tailored advice to enhance your tech infrastructure and strategies for a digital age.",
          services_three_second_services_section_three_power_phrase: "Empowering Strategies with Tech Insight",
        
          services_three_second_services_section_four_title_superior: "Design UI/UX",
          services_three_second_services_section_four_title: "Design UI/UX",
          services_three_second_services_section_four_subtitle: "Elevating online presence with creative, user-centric design. Our UI/UX solutions promise seamless navigation, aesthetic appeal, and unmatched user satisfaction.",
          services_three_second_services_section_four_power_phrase: "Creating Compelling Digital Narratives",
        
          services_three_second_services_get_started_button: "Get Started",

          
          resources_download_ebook_section_title_mini:"Free ebook",
          resources_download_ebook_section_title:"Download our eBook on automations",
          resources_download_ebook_section_title_story_one:"In this EBook you will find valuable information about the four packages we offer. Experiment, create and automate processes.",
          resources_download_ebook_section_title_story_one_author:"Max Innovator",
          resources_download_ebook_section_title_story_one_position:"Chief Experiment Officer (CEO of Ideas)",
          resources_download_ebook_section_title_story_two:"You can create strategies with our downloadable resource, catch up on what your competitors are applying and leave the work to the machines.",
          resources_download_ebook_section_title_story_two_author:"Alex Murphy a.k.a. RoboCop",
          resources_download_ebook_section_title_story_two_position:"Director of Automated Strategy Enforcement",
          resources_download_ebook_section_download_history:"Enter your email and you will receive an email with our free EBook on how to automate your business and spend time on what is really important.",
          resources_download_ebook_section_language_ebook:"EBook Language",
          resources_download_ebook_section_boton_download:"Download",


          blog_newsletter_title: "Newsletter",
          blog_newsletter_subtitle: "Subscribe to our newsletter today to receive updates on the latest trends in integration and automation.",
          blog_newsletter_newsletter_language: "Newsletter language",
          blog_newsletter_policy_one: "I accept the",
          blog_newsletter_policy_two: " Privacy Policy",
          blog_newsletter_email_adress_input: "Email Address",
          blog_newsletter_subscribe_button: "Subscribe",
        
          blog_recent_news_title: "Recent News",
          blog_categories_title: "Categories",
          blog_recent_comments_title: "Recent Comments",
          blog_recent_comments_empty_title: "There are no comments",
          blog_recent_comments_empty_subtitle: "Be the first to leave one!",
          blog_read_more_card: "Read More",
          blog_comments_card: "Comments",
        
          blog_comments_reply: "Leave a Reply",
          blog_comments_email_not_published: "Your email address will not be published",
          blog_comments_consent: "By accepting the policy I consent to my name and email being stored and used to send me updates and news.",
        
          blog_details_article_category: "Category:",
          blog_details_article_share: "Share:",
          blog_details_article_author: "Author",
          blog_details_article_last_comments: "Last comments:",
        
          generic_your_message: "Your message...",
          generic_your_name: "Your Name",
          generic_your_email: "Your Email",
          generic_send_message: "Send Message",
        
          generic_newsletter_toast_error_politics: "You must accept the privacy policy to subscribe.",
          generic_newsletter_toast_error_select_language: "Please select a language.",
          generic_toast_success_send_message: "Message sent successfully",
          generic_toast_success_send_message_moderation: "Your message has been successfully submitted. It will be reviewed by our moderators before being published. Thank you for your contribution!",
          generic_toast_error_notsend_message: "Could not send the message",



          // POLICIES
          newsletter_policies_title_footer: "Newsletter Policies",
          policies_title_mini_newsletter: "Terms and conditions",
          policies_newsletter_title: "Newsletter Policies",
          policies_newsletter_title_one: "Newsletter Subscription Policy",
          policies_newsletter_description_one: "By subscribing to our newsletter, you agree to receive periodic communications from us, which may include news, updates, promotions, and exclusive content related to Invoke App. We are committed to using your personal information (such as your email address) exclusively for these purposes and in accordance with our Privacy Policy.",
          policies_newsletter_title_two: "Collection of Information",
          policies_newsletter_description_two: "To subscribe to our newsletter, we only require your email address. Additional information may be requested to personalize your experience, but providing such information is entirely voluntary.",
          policies_newsletter_title_three: "Use of Information",
          policies_newsletter_description_three: "The information you provide is used to send you our newsletters and related communications. We will not share your personal information with third parties without your explicit consent, except as necessary for the email service or as required by law.",
          policies_newsletter_title_four: "Opt-Out Right",
          policies_newsletter_description_four: "You have the right to unsubscribe from our newsletter at any time. Each email sent will include a link at the bottom to `unsubscribe,` allowing you to cancel your subscription easily and automatically.",
          policies_newsletter_title_five: "Policy Updates",
          policies_newsletter_description_five: "We reserve the right to modify this subscription policy at any time. Updates will be effective immediately upon posting on our website. By continuing to use our newsletter service after such updates, you agree to the modifications.",
          policies_newsletter_title_six: "Contact",
          policies_newsletter_description_six: "If you have questions or concerns about our subscription policy, please contact us through",

          ebook_download_policies_title_footer: "EBook Download Policies",
          policies_title_mini_ebook: "Terms and conditions",
          policies_ebook_download_title: "EBook Download Policies",
          policies_ebook_download_title_one: "EBook Download Policy",
          policies_ebook_download_description_one: "By downloading our eBook, you consent to provide us with your email address and agree to receive periodic communications from Invoke App. These communications may include updates, news, and exclusive content relevant to our offerings. Your personal information will be handled in accordance with our Privacy Policy, ensuring its use solely for the purposes mentioned.",
          policies_ebook_download_title_two: "Information Collection",
          policies_ebook_download_description_two: "To download our eBook, we require your email address. While we may request additional information to tailor your experience, the provision of such details is completely optional.",
          policies_ebook_download_title_three: "Use of Information",
          policies_ebook_download_description_three: "The information collected will be used to facilitate your eBook download and to send you related communications. We pledge not to share your personal data with third parties without your explicit permission, except as needed for service provision or as mandated by law.",
          policies_ebook_download_title_four: "Opt-Out Right",
          policies_ebook_download_description_four: "You reserve the right to opt out of receiving our communications at any time. Each communication will include an `unsubscribe` link, allowing you to cease subscription effortlessly.",
          policies_ebook_download_title_five: "Policy Modifications",
          policies_ebook_download_description_five: "We retain the right to alter these eBook download policies at our discretion. Changes become effective immediately upon their publication on our site. Your continued engagement with our eBook downloads signifies your acceptance of these changes.",
          policies_ebook_download_title_six: "Inquiries",
          policies_ebook_download_description_six: "Should you have any questions or concerns regarding our eBook download policies, please feel free to reach out to us through",
        
          contact_policies_title_footer: "Contact Policies",
          policies_title_mini_contact: "Terms and Conditions",
          policies_contact_title: "Contact Policies",
          policies_contact_title_one: "Information Collection for Contact",
          policies_contact_description_one: "When you contact us via email or through our website, we collect your email address and any other information you choose to provide. This information is used to respond to your inquiries and provide support or information related to our services.",
          policies_contact_title_two: "Use of Contact Information",
          policies_contact_description_two: "Your contact information, primarily your email address, will be used to communicate with you in response to your inquiries. We may also use this information to send you updates or information about our services if you have consented to receive such communications.",
          policies_contact_title_three: "Protection of Your Information",
          policies_contact_description_three: "We are committed to protecting the information you share with us. Your contact information will be stored securely and will not be shared with third parties without your explicit consent, except as necessary to provide you with the services requested or as required by law.",
          policies_contact_title_four: "Opting Out",
          policies_contact_description_four: "You have the right to opt out of receiving communications from us at any time. You can do this by using the unsubscribe link provided in our emails or by contacting us directly to request removal from our contact list.",
          policies_contact_title_five: "Policy Updates",
          policies_contact_description_five: "We reserve the right to update these contact policies at any time. Changes will take effect immediately upon their posting on our website. Continuing to contact us following such changes constitutes your acceptance of the new terms.",
          policies_contact_title_six: "Contact Us",
          policies_contact_description_six: "If you have any questions or concerns about our contact policies, please do not hesitate to reach out to us through",
        
          comment_policies_title_footer: "Comment Policies",
          policies_title_mini_comment: "Terms and Conditions",
          policies_comment_title: "Comment Policies",
          policies_comment_title_one: "Comment Posting Rules",
          policies_comment_description_one: "We welcome and encourage comments on our blog posts. However, all comments must comply with our community standards, including respect for others, no hate speech, and no promotion of illegal activities. We reserve the right to remove any comments that violate these standards.",
          policies_comment_title_two: "Use of Personal Information",
          policies_comment_description_two: "To post a comment, you will be required to provide your email address. This information is used to manage comments and prevent abuse. Your email address will not be displayed publicly or used for any purposes other than comment management, without your explicit consent.",
          policies_comment_title_three: "Moderation and Approval",
          policies_comment_description_three: "All comments are subject to moderation. We aim to review and approve comments promptly, but there may be delays. We reserve the right to edit or not publish any comment for any reason, in accordance with our standards.",
          policies_comment_title_four: "Rights to Comments",
          policies_comment_description_four: "By posting a comment, you grant us a perpetual, worldwide, non-exclusive, royalty-free license to use, reproduce, modify, publish, translate, create derivative works from, distribute, and display such comments in any media.",
          policies_comment_title_five: "Policy Updates",
          policies_comment_description_five: "We reserve the right to modify these comment policies at any time. Changes will be effective immediately upon posting on our website. By continuing to post comments after such changes, you agree to the new policies.",
          policies_comment_title_six: "Contact",
          policies_comment_description_six: "If you have questions or concerns about our comment policies, please contact us through",
        
          cookies_policies_title_footer: "Cookie Policies",
          cookies_policies_title_mini: "Terms & Conditions",
          cookies_policies_title: "Cookie Policies",
          cookies_policy_title_one: "Introduction",
          cookies_policy_description_one: "By visiting our website, you agree to our use of cookies to enhance your browsing experience. Cookies allow us to personalize content, offer social media features, and analyze our traffic.",
          cookies_policy_title_two: "Information Collection",
          cookies_policy_description_two: "We use cookies that are essential for the operation of the site, as well as analytics cookies that help us understand how you interact with the site.",
          cookies_policy_title_three: "Use of Information",
          cookies_policy_description_three: "Information collected through cookies is used to personalize your experience and deliver relevant advertising. It is also used to improve our website and services.",
          cookies_policy_title_four: "Managing Cookies",
          cookies_policy_description_four: "You can manage your cookie preferences at any time by adjusting your browser settings. Please be aware that changing these settings may affect your experience on the site.",
          cookies_policy_title_five: "Consent",
          cookies_policy_description_five: "By using our site, you consent to the use of cookies in accordance with this policy. If you choose not to accept our cookies, some parts of the site may not function as expected.",
          cookies_policy_title_six: "Contact",
          cookies_policy_description_six: "If you have questions about our cookie policy, please feel free to contact us at letstalk@invokeapp.io"
          


        }
      },
      es: {
        translation: {
          navbar_home: "Inicio",
          navbar_about_us: "Nosotros",
          navbar_services: "Servicios",
          navbar_portfolio: "Funcionalidades",
          navbar_resources: "Recursos",
          navbar_blog: "Blog",
          contact_us: "Hablemos",
          cookies_text: "Utilizamos cookies para mejorar tu experiencia en nuestra página. Al continuar navegando en este sitio, aceptas nuestro uso de cookies. Estas cookies nos permiten ofrecerte una experiencia personalizada, mostrar publicidad relevante y obtener estadísticas de uso del sitio. Para más información sobre el uso de cookies y cómo gestionarlas, consulta nuestra Política de Cookies. Al hacer clic en 'Aceptar todas', consientes el uso de todas las cookies.",
          cookies_button: "Aceptar todas",

    
          page_title_about_us: "Nosotros",
          page_title_services: "Servicios",
          page_title_portfolio: "Funcionalidades",
          page_title_resources: "Recursos",
          page_title_blog: "Blog",
          page_title_blog_details: "Detalles del Artículo",
          page_title_policies: "Políticas",
          page_title_contact_us: "Contáctanos",
    






          home_slider_title_superior_one: "Impulsamos tu negocio",
          home_slider_title_superior_two: "Soluciones integrales para emprendedores",
          home_slider_title_main_copy_one1: "Adelanta a tu competencia en un abrir y cerrar de ojos",
          home_slider_title_main_copy_one2: "y simplifica tu mundo digital",
          home_slider_title_main_copy_two1: "Automatiza tu negocio y échate a dormir",
          home_slider_title_main_copy_two2: "eficiencia, velocidad y escalabilidad",
    
          core_coreprelaunch_title_mini: "core.invokeapp",
          core_coreprelaunch_title_slogan: "ERP for visionaries",
          core_coreprelaunch_description: "¡Regístrese para el lanzamiento previo y sea uno de los privilegiados de lanzar su negocio como uno de los primeros en adoptarlo!",
          core_coreprelaunch_button_text: "Únete",
          core_coreprelaunch_title_more_info: "Information",
          core_coreprelaunch_description1_more_info: "Nuestro sistema ERP revoluciona la gestión empresarial al integrarse con plataformas clave de terceros, lo que permite una automatización y sincronización de datos sin inconvenientes. Cuenta con módulos robustos para análisis, control financiero y gestión de la empresa, que incluyen la incorporación de empleados y la asignación de roles. Un centro de aprendizaje dedicado respalda el crecimiento empresarial con recursos esenciales. Con potentes capacidades de automatización, el sistema reduce significativamente el trabajo manual, lo que mejora la eficiencia y brinda información integral para la toma de decisiones estratégicas. Esta herramienta integral ofrece a las empresas una solución poderosa y tentacular para navegar y prosperar en el panorama competitivo del mercado.",
          core_coreprelaunch_description2_more_info: "Únase a nuestro equipo y eleve su proyecto con nuestro avanzado sistema ERP. Aproveche el poder de las integraciones sin inconvenientes y la automatización estratégica para transformar sus operaciones comerciales y alcanzar nuevas cotas de éxito.",
          core_coreprelaunch_button_more_information: "¿Más información?",
          
                    
          home_two_features_title_superior_mini: "La herramienta de los visionarios",
          home_two_features_title_one: "Tu proyecto ",
          home_two_features_title_two: "despegando",
          home_two_features_subtitle: "Explora nuevos horizontes con automatizaciones que liberan tu potencial. Sumérgete en un mundo donde dejas de tomar decisiones basadas en la intuición.",
          home_two_features_button_text: "Únete al Prelanzamiento",
          home_two_features_card_title_one: "Maximización de Tiempo",
          home_two_features_card_description_one: "Reinventa el manejo del tiempo con automatizaciones que simplifican tareas y te devuelven horas valiosas.",
          home_two_features_card_title_two: "Eficiencia Operativa",
          home_two_features_card_description_two: "Optimiza cada proceso y reduce costos, transformando tu operación en un modelo de eficiencia y sostenibilidad.",
          home_two_features_card_title_three: "Retención de Clientes",
          home_two_features_card_description_three: "Fortalece lazos con tus clientes mediante interacciones significativas y personalizadas, impulsadas por IA.",
          home_two_features_card_title_four: "Conversión de Leads",
          home_two_features_card_description_four: "Maximiza tu embudo de ventas con estrategias de seguimiento automático que convierten más leads en clientes satisfechos.",
    

          home_about_title_mini: "Nuestra Esencia",
          home_about_title_big: "Simplificando Tu Éxito",
          home_about_subtitle: "Sumérgete en un mundo donde la tecnología sirve a la creatividad. En invokeapp, nos especializamos en crear integraciones y automatizaciones fluidas que potencian tu presencia digital y optimizan tu flujo de trabajo.",
          home_about_caption_one: "Integraciones Sin Esfuerzo",
          home_about_caption_two: "Maestría en Automatización",
          home_about_caption_three: "Libertad Creativa",
          home_about_discover_services: "Explora las Posibilidades",
    
          home_capabilities_title_mini: "Nuestra Visión",
          home_capabilities_title_big: "Conectando con la Tecnología",
          home_capabilities_subtitle: "Desde la chispa de una idea hasta su ejecución, nos enfocamos en entregar soluciones que no solo funcionan, sino que hacen maravillas por ti. Descubre cómo nuestra experiencia en integración y automatización puede transformar tu negocio.",
          home_capabilities_caption_one: "- De la Idea a la Ejecución",
          home_capabilities_caption_two: "- Del Diseño al Lanzamiento",
          home_capabilities_caption_three: "- De la Prueba al Triunfo",
    
          home_services_title_mini: "Pisa el acelerador",
          home_services_title_big: "Eleva Tu Flujo de Trabajo",
          home_services_title_mobileapps: "Plataformas Integradas",
          home_services_subtitle_mobileapps: "Crea soluciones que conecten tu contenido con tu audiencia y empieza a delegar tu proyecto.",
          home_services_title_backend: "Sistemas Automatizados",
          home_services_subtitle_backend: "Asegura que tu proyecto sea tan inteligente como tus ideas, con sistemas que se comunican entre sí y automatizan tus procesos.",
          home_services_title_consulting: "Perspectivas Estratégicas",
          home_services_subtitle_consulting: "Avanza con nuestra servicio de soporte para diseñar soluciones específicas a tu modelo de negocio.",
          home_services_title_database: "Datos al Alcance de Tu Mano",
          home_services_subtitle_database: "Organiza, accede y utiliza tus datos con sistemas diseñados para la eficiencia.",
          home_services_title_analytics: "De los Datos a la Acción",
          home_services_subtitle_analytics: "Transforma los datos en decisiones con análisis que resaltan oportunidades para la automatización y la eficiencia.",
          home_services_title_uiuxdesign: "Experiencias Atractivas",
          home_services_subtitle_uiuxdesign: "Diseño que comprende el viaje de tu proyecto, optimizado para la interacción y conversión.",
          home_services_title_webapps: "Aplicación Sin Fisuras",
          home_services_subtitle_webapps: "Aplicación poderosa pero intuitiva, que unen tus servicios con tu audiencia.",
          home_services_title_saas: "Control de tu compañía",
          home_services_subtitle_saas: "Extiende el alcance y capacidades de tu servicio agregando personal a la plataforma de manera sencilla.",
    
          home_contact_area_title_mini: "Crecemos si tu lo haces primero",
          home_contact_area_title_big: "¿Preguntas? ¿Ideas? Pruébanos",
    
          home_brands_customers_title_mini: "Socios en el Éxito",
          home_brands_customers_title_big: "Empoderandote con más de 100 plataformas",
          home_brands_customers_subtitle: "Tu visión, nuestra tecnología. Juntos, creamos experiencias digitales que marcan la diferencia.",
    
          home_contact_title: "Conéctate con Nosotros",
          home_contact_address: "Donde las Ideas Encuentran Soluciones",
          home_contact_email: "Habla con Nosotros",
    
          home_footer_title_company: "Navega",
          home_footer_company_about_us: "Nosotros",
          home_footer_company_services: "Soluciones",
          home_footer_company_consulting: "Consultoría Tech",
          home_footer_company_blog: "Historias",
          home_footer_company_policies: "Políticas",
          home_footer_company_contactus: "Conecta",
          home_footer_title_resources: "Herramientas",
          home_footer_resources_customers: "Casos de éxito",
          home_footer_resources_pricing: "Valor",
          home_footer_resources_news: "Blog",
          home_footer_resources_support: "Soporte",
          home_footer_title_usecases: "Otros servicios",
          home_footer_usecases_uiuxdesign: "UI/UX Diseño",
          home_footer_usecases_mobileapp: "Apps Móviles",
          home_footer_usecases_apiintegration: "Integraciones API",
          home_footer_usecases_database: "Bases de datos",
          home_footer_usecases_software: "SaaS",
          home_footer_usecases_analytics: "Analítica",
          home_footer_title_information: "Aprende Más",

          about_us_two_faq_title_mini: "Perspectivas",
          about_us_two_faq_title_big_one: "Experiencia Tecnológica",
          about_us_two_faq_title_big_two: "para la Integración Efectiva",
          about_us_two_faq_function_one_title: "Simplificando Conceptos Complejos",
          about_us_two_faq_function_one_content: "Haciendo accesibles conceptos tecnológicos complejos, porque la innovación prospera en la simplicidad.",
          about_us_two_faq_function_two_title: "Rendimiento Digital Mejorado",
          about_us_two_faq_function_two_content: "Optimizando plataformas digitales para el máximo rendimiento, garantizando velocidad, eficiencia y satisfacción.",
          about_us_two_faq_function_three_title: "Soluciones Integradas",
          about_us_two_faq_function_three_content: "Conectando sistemas y herramientas sin esfuerzo para mejorar la eficiencia y el trabajo en equipo, impulsando tus proyectos hacia adelante.",
        
          about_us_two_achievement_title_mini: "Logros",
          about_us_two_achievement_title_big: "Abanderando la Excelencia Técnica",
          about_us_two_achievement_subtitle: "Impulsa la innovación y la excelencia con nosotros. Elevando proyectos y la satisfacción del cliente a nuevas alturas.",
          about_us_two_achievement_function_one: "Integraciones Fluidas",
          about_us_two_achievement_function_two: "Soluciones Avanzadas en Apps Móviles",
          about_us_two_achievement_discover_more: "Descubre Más",
        
          services_three_second_services_title: "Nuestra Experiencia",
          services_three_second_services_subtitle: "Guiándote a través de los desafíos de hoy con nuestro equipo de primera.",
        
          services_three_second_services_section_one_title_superior: "Aplicaciones Móviles",
          services_three_second_services_section_one_title: "Aplicaciones Móviles",
          services_three_second_services_section_one_subtitle: "Transformando ideas en aplicaciones móviles atractivas y centradas en el usuario. Ofreciendo experiencias destacadas en iOS y Android, combinando funcionalidad con belleza.",
          services_three_second_services_section_one_power_phrase: "Creando Viajes de Apps Inmersivos",
        
          services_three_second_services_section_two_title_superior: "Backend e Integraciones",
          services_three_second_services_section_two_title: "Backend e Integraciones",
          services_three_second_services_section_two_subtitle: "Desarrollando backends fuertes y escalables. Dominando datos, interacciones de usuarios y suaves integraciones de servicios de terceros para una experiencia completa.",
          services_three_second_services_section_two_power_phrase: "Diseñando Ecosistemas de Backend Unificados",
        
          services_three_second_services_section_three_title_superior: "Consultoría Tecnológica",
          services_three_second_services_section_three_title: "Consultoría Tecnológica",
          services_three_second_services_section_three_subtitle: "Navegando el paisaje tecnológico con perspectivas estratégicas y consejos a medida para mejorar tu infraestructura tecnológica y estrategias para una era digital.",
          services_three_second_services_section_three_power_phrase: "Estrategias Potenciadas con Perspectiva Tecnológica",
        
          services_three_second_services_section_four_title_superior: "Diseño UI/UX",
          services_three_second_services_section_four_title: "Diseño UI/UX",
          services_three_second_services_section_four_subtitle: "Elevando la presencia en línea con diseño creativo y centrado en el usuario. Nuestras soluciones UI/UX prometen una navegación sin fisuras, atractivo estético y satisfacción de usuario sin igual.",
          services_three_second_services_section_four_power_phrase: "Creando Narrativas Digitales Atractivas",
        
          services_three_second_services_get_started_button: "Comenzar",



          resources_download_ebook_section_title_mini: "Ebook gratuito",
          resources_download_ebook_section_title: "Descarga nuestro eBook sobre automatizaciones",
          resources_download_ebook_section_title_story_one: "En este eBook encontrarás información valiosa sobre los cuatro paquetes que ofrecemos. Experimenta, crea y automatiza procesos.",
          resources_download_ebook_section_title_story_one_author: "Max Innovator",
          resources_download_ebook_section_title_story_one_position: "Jefe de Experimentación de Ideas (CEO of Ideas)",
          resources_download_ebook_section_title_story_two: "Puedes crear estrategias con nuestro recurso descargable, ponerte al día sobre lo que tus competidores están aplicando y dejar el trabajo a las máquinas.",
          resources_download_ebook_section_title_story_two_author: "Alex Murphy alias RoboCop",
          resources_download_ebook_section_title_story_two_position: "Director de Cumplimiento de Estrategias Automatizadas",
          resources_download_ebook_section_download_history: "Ingresa tu correo electrónico y recibirás un email con nuestro eBook gratuito sobre cómo automatizar tu negocio y dedicar tiempo a lo que realmente importa.",
          resources_download_ebook_section_language_ebook: "Idioma del eBook",
          resources_download_ebook_section_boton_download: "Descargar",
        



          blog_newsletter_title: "Newsletter",
          blog_newsletter_subtitle: "Suscríbete a nuestra newsletter hoy para recibir actualizaciones sobre las últimas tendencias en integración y automatización.",
          blog_newsletter_newsletter_language: "Idioma de la Newsletter",
          blog_newsletter_policy_one: "Acepto la ",
          blog_newsletter_policy_two: "Política de Privacidad",
          blog_newsletter_email_adress_input: "Dirección de Email",
          blog_newsletter_subscribe_button: "Suscribirse",
        
          blog_recent_news_title: "Noticias Recientes",
          blog_categories_title: "Categorías",
          blog_recent_comments_title: "Comentarios Recientes",
          blog_recent_comments_empty_title: "No hay comentarios",
          blog_recent_comments_empty_subtitle: "¡Sé el primero en dejar uno!",
          blog_read_more_card: "Leer Más",
          blog_comments_card: "Comentarios",
        
          blog_comments_reply: "Deja un Comentario",
          blog_comments_email_not_published: "Tu dirección de email no será publicada",
          blog_comments_consent: "Al aceptar la política doy mi consentimiento para que mi nombre y email sean almacenados y utilizados para enviarme actualizaciones y noticias.",
        
          blog_details_article_category: "Categoría:",
          blog_details_article_share: "Compartir:",
          blog_details_article_author: "Autor",
          blog_details_article_last_comments: "Últimos comentarios:",
        
          generic_your_message: "Tu mensaje...",
          generic_your_name: "Tu Nombre",
          generic_your_email: "Tu Email",
          generic_send_message: "Enviar Mensaje",
        
          generic_newsletter_toast_error_politics: "Debes aceptar la política de privacidad para suscribirte.",
          generic_newsletter_toast_error_select_language: "Por favor, selecciona un idioma.",
          generic_toast_success_send_message: "Mensaje enviado con éxito",
          generic_toast_success_send_message_moderation: "Tu mensaje ha sido enviado con éxito. Será revisado por nuestros moderadores antes de ser publicado. ¡Gracias por tu contribución!",
          generic_toast_error_notsend_message: "No se pudo enviar el mensaje",



          // POLICIES
          newsletter_policies_title_footer: "Políticas de la Newsletter",
          policies_title_mini: "Términos y condiciones",
          policies_newsletter_title: "Políticas del Boletín",
          policies_newsletter_title_one: "Política de Suscripción al Boletín",
          policies_newsletter_description_one: "Al suscribirte a nuestro boletín, aceptas recibir comunicaciones periódicas de nuestra parte, que pueden incluir noticias, actualizaciones, promociones y contenido exclusivo relacionado con Invoke App. Nos comprometemos a utilizar tu información personal (como tu dirección de correo electrónico) exclusivamente para estos fines y de acuerdo con nuestra Política de Privacidad.",
          policies_newsletter_title_two: "Recopilación de Información",
          policies_newsletter_description_two: "Para suscribirte a nuestro boletín, solo requerimos tu dirección de correo electrónico. Puede solicitarse información adicional para personalizar tu experiencia, pero proporcionar dicha información es completamente voluntario.",
          policies_newsletter_title_three: "Uso de la Información",
          policies_newsletter_description_three: "La información que proporcionas se utiliza para enviarte nuestros boletines y comunicaciones relacionadas. No compartiremos tu información personal con terceros sin tu consentimiento explícito, excepto cuando sea necesario para el servicio de correo electrónico o según lo requiera la ley.",
          policies_newsletter_title_four: "Derecho de Baja",
          policies_newsletter_description_four: "Tienes el derecho de darte de baja de nuestro boletín en cualquier momento. Cada correo enviado incluirá un enlace en la parte inferior para `darse de baja`, permitiéndote cancelar tu suscripción de manera fácil y automática.",
          policies_newsletter_title_five: "Actualizaciones de la Política",
          policies_newsletter_description_five: "Nos reservamos el derecho de modificar esta política de suscripción en cualquier momento. Las actualizaciones serán efectivas inmediatamente después de su publicación en nuestro sitio web. Al continuar utilizando nuestro servicio de boletín después de tales actualizaciones, aceptas las modificaciones.",
          policies_newsletter_title_six: "Contacto",
          policies_newsletter_description_six: "Si tienes preguntas o inquietudes sobre nuestra política de suscripción, por favor contáctanos a través de",
        
          ebook_download_policies_title_footer: "Políticas de Descarga del EBook",
          policies_title_mini_ebook: "Términos y condiciones",
          policies_ebook_download_title: "Políticas de Descarga de EBook",
          policies_ebook_download_title_one: "Política de Descarga de EBook",
          policies_ebook_download_description_one: "Al descargar nuestro EBook, consientes en proporcionarnos tu dirección de correo electrónico y aceptas recibir comunicaciones periódicas de Invoke App. Estas comunicaciones pueden incluir actualizaciones, noticias y contenido exclusivo relevante para nuestras ofertas. Tu información personal será manejada de acuerdo con nuestra Política de Privacidad, asegurando su uso exclusivamente para los fines mencionados.",
          policies_ebook_download_title_two: "Recolección de Información",
          policies_ebook_download_description_two: "Para descargar nuestro EBook, requerimos tu dirección de correo electrónico. Aunque podemos solicitar información adicional para personalizar tu experiencia, la provisión de tales detalles es completamente opcional.",
          policies_ebook_download_title_three: "Uso de la Información",
          policies_ebook_download_description_three: "La información recopilada será utilizada para facilitar la descarga de tu EBook y enviarte comunicaciones relacionadas. Nos comprometemos a no compartir tus datos personales con terceros sin tu permiso explícito, excepto según sea necesario para la prestación del servicio o según lo exija la ley.",
          policies_ebook_download_title_four: "Derecho de Cancelación",
          policies_ebook_download_description_four: "Reservas el derecho de optar por no recibir nuestras comunicaciones en cualquier momento. Cada comunicación incluirá un enlace de `cancelar suscripción`, permitiéndote terminar la suscripción de manera sencilla.",
          policies_ebook_download_title_five: "Modificaciones a la Política",
          policies_ebook_download_description_five: "Nos reservamos el derecho de modificar estas políticas de descarga de EBook a nuestro criterio. Los cambios se vuelven efectivos inmediatamente tras su publicación en nuestro sitio. Tu continua interacción con nuestras descargas de EBook señala tu aceptación de estos cambios.",
          policies_ebook_download_title_six: "Consultas",
          policies_ebook_download_description_six: "Si tienes alguna pregunta o inquietud respecto a nuestras políticas de descarga de EBook, no dudes en ponerte en contacto con nosotros a través de",
        
          contact_policies_title_footer: "Políticas de Contacto",
          policies_title_mini_contact: "Términos y Condiciones",
          policies_contact_title: "Políticas de Contacto",
          policies_contact_title_one: "Recolección de Información para Contacto",
          policies_contact_description_one: "Cuando te pones en contacto con nosotros vía email o a través de nuestra página web, recopilamos tu dirección de correo electrónico y cualquier otra información que elijas proporcionar. Esta información se utiliza para responder a tus consultas y ofrecer soporte o información relacionada con nuestros servicios.",
          policies_contact_title_two: "Uso de la Información de Contacto",
          policies_contact_description_two: "Tu información de contacto, principalmente tu dirección de correo electrónico, será utilizada para comunicarnos contigo en respuesta a tus consultas. También podemos usar esta información para enviarte actualizaciones o información sobre nuestros servicios si has consentido recibir tales comunicaciones.",
          policies_contact_title_three: "Protección de Tu Información",
          policies_contact_description_three: "Estamos comprometidos a proteger la información que compartes con nosotros. Tu información de contacto será almacenada de manera segura y no será compartida con terceros sin tu consentimiento explícito, excepto lo necesario para proporcionarte los servicios solicitados o según lo requerido por la ley.",
          policies_contact_title_four: "Opción de Exclusión",
          policies_contact_description_four: "Tienes el derecho de optar por no recibir comunicaciones de nuestra parte en cualquier momento. Puedes hacerlo utilizando el enlace de cancelación de suscripción proporcionado en nuestros correos electrónicos o contactándonos directamente para solicitar la eliminación de nuestra lista de contactos.",
          policies_contact_title_five: "Actualizaciones de la Política",
          policies_contact_description_five: "Nos reservamos el derecho de actualizar estas políticas de contacto en cualquier momento. Los cambios entrarán en vigor inmediatamente tras su publicación en nuestra página web. Continuar contactándonos tras dichos cambios constituye tu aceptación de los nuevos términos.",
          policies_contact_title_six: "Contáctanos",
          policies_contact_description_six: "Si tienes alguna pregunta o preocupación sobre nuestras políticas de contacto, no dudes en ponerte en contacto con nosotros a través de",
        
          comment_policies_title_footer: "Políticas de Comentarios",
          policies_title_mini_comment: "Términos y Condiciones",
          policies_comment_title: "Políticas de Comentarios",
          policies_comment_title_one: "Reglas para Publicar Comentarios",
          policies_comment_description_one: "Damos la bienvenida y alentamos los comentarios en nuestras publicaciones del blog. Sin embargo, todos los comentarios deben cumplir con nuestros estándares de comunidad, incluyendo el respeto hacia los demás, no al discurso de odio y no a la promoción de actividades ilegales. Nos reservamos el derecho de eliminar cualquier comentario que viole estos estándares.",
          policies_comment_title_two: "Uso de Información Personal",
          policies_comment_description_two: "Para publicar un comentario, se requerirá que proporciones tu dirección de correo electrónico. Esta información se utiliza para gestionar los comentarios y prevenir abusos. Tu dirección de correo electrónico no se mostrará públicamente ni se utilizará para otros fines que no sean la gestión de comentarios, sin tu consentimiento explícito.",
          policies_comment_title_three: "Moderación y Aprobación",
          policies_comment_description_three: "Todos los comentarios están sujetos a moderación. Nuestro objetivo es revisar y aprobar los comentarios de manera pronta, pero puede haber retrasos. Nos reservamos el derecho de editar o no publicar cualquier comentario por cualquier motivo, de acuerdo con nuestros estándares.",
          policies_comment_title_four: "Derechos sobre los Comentarios",
          policies_comment_description_four: "Al publicar un comentario, nos otorgas una licencia perpetua, mundial, no exclusiva, libre de regalías para usar, reproducir, modificar, publicar, traducir, crear obras derivadas de, distribuir y mostrar dichos comentarios en cualquier medio.",
          policies_comment_title_five: "Actualizaciones de la Política",
          policies_comment_description_five: "Nos reservamos el derecho de modificar estas políticas de comentarios en cualquier momento. Los cambios serán efectivos inmediatamente después de su publicación en nuestro sitio web. Al continuar publicando comentarios después de dichos cambios, aceptas las nuevas políticas.",
          policies_comment_title_six: "Contacto",
          policies_comment_description_six: "Si tienes preguntas o preocupaciones acerca de nuestras políticas de comentarios, por favor contáctanos a través de",

          cookies_policies_title_footer: "Políticas de Cookies",
          cookies_policies_title_mini: "Términos y Condiciones",
          cookies_policies_title: "Políticas de Cookies",
          cookies_policy_title_one: "Introducción",
          cookies_policy_description_one: "Al visitar nuestro sitio web, aceptas el uso de cookies para mejorar tu experiencia de navegación. Las cookies nos permiten personalizar contenido, ofrecer funciones de redes sociales y analizar nuestro tráfico.",
          cookies_policy_title_two: "Recopilación de Información",
          cookies_policy_description_two: "Utilizamos cookies que son esenciales para el funcionamiento del sitio, así como cookies de análisis que nos ayudan a comprender cómo interactúas con el sitio.",
          cookies_policy_title_three: "Uso de la Información",
          cookies_policy_description_three: "La información recopilada mediante cookies se utiliza para personalizar tu experiencia y enviar publicidad relevante. También se utiliza para mejorar nuestro sitio web y servicios.",
          cookies_policy_title_four: "Administración de Cookies",
          cookies_policy_description_four: "Puedes gestionar tus preferencias de cookies en cualquier momento ajustando la configuración de tu navegador.",
          cookies_policy_title_five: "Consentimiento",
          cookies_policy_description_five: "Al utilizar nuestro sitio, consientes el uso de cookies de acuerdo con esta política. Si decides no aceptar nuestras cookies, algunas partes del sitio pueden no funcionar como se espera.",
          cookies_policy_title_six: "Contacto",
          cookies_policy_description_six: "Si tienes preguntas sobre nuestra política de cookies, no dudes en contactarnos en"




        }
      }
    }
  }
  );

export default i18n;
























///ORIGINAL TRANSLATION IN ENGLISH

// en: {
//   translation: {
//    navbar_home: "Home",
//    navbar_about_us: "About Us",
//    navbar_services: "Services",
//    navbar_portfolio: "Portfolio",
//    navbar_resources: "Resources",
//    navbar_blog: "Blog",
//    contact_us: "Contact Us",

//    page_title_about_us:"About Us",
//    page_title_services:"Services",
//    page_title_portfolio:"Our Portfolio",
//    page_title_resources:"Resources",
//    page_title_blog:"Our Blog",
//    page_title_blog_details:"Blog Details",
//    page_title_contact_us:"Contact Us",
   

//   /// HOME PAGE AND COMPONENTS
//    home_slider_title_superior_one: "Elevating your experience",
//    home_slider_title_superior_two: "We provide faster products to infoproducters",
//    home_slider_title_main_copy_one1: "We provide fast and beautiful apps ",
//    home_slider_title_main_copy_one2: "in a lightweight package",
//    home_slider_title_main_copy_two1: "Our technology is used in Google, Instagram ",
//    home_slider_title_main_copy_two2: "and other big platforms",
   
//    home_about_title_mini:"Who we are",
//    home_about_title_big:"Unlocking Technological Potential",
//    home_about_subtitle:"Welcome to our technology consulting and development firm! We are dedicated to pushing boundaries and unlocking the true potential of your business through innovative and cutting-edge solutions.",
//    home_about_caption_one:"Custom Mobile App Development",
//    home_about_caption_two:"User-Centric Design",
//    home_about_caption_three:"Scalable and Reliable Solutions",
//    home_about_discover_services:"Discover Our Services",

//    home_capabilities_title_mini:"Capabilities",
//    home_capabilities_title_big:"Strategy-led Mobile App Design & Development",
//    home_capabilities_subtitle:"At our technology consultancy, we bring your app ideas to life with a strategy-led approach and attention to every detail of mobile app design and development. Our capabilities encompass the following stages:",
//    home_capabilities_caption_one:"- Analysis & Idea",
//    home_capabilities_caption_two:"- Design & Development",
//    home_capabilities_caption_three:"- Testing & Launch",
   
//    home_services_title_mini:"Our Services",
//    home_services_title_big:"Provide awesome customer service with our tools.",
//    home_services_title_mobileapps : "Mobile Apps",
//    home_services_subtitle_mobileapps : "Create intuitive and powerful mobile applications that elevate user experiences and drive business growth.",
//    home_services_title_backend : "Backends & APIs",
//    home_services_subtitle_backend : "Build robust backends and APIs that ensure seamless communication and data management for your applications.",
//    home_services_title_consulting : "Consulting",
//    home_services_subtitle_consulting : "Leverage our expert technology consulting services to make informed decisions and accelerate your digital transformation.",
//    home_services_title_database : "Database",
//    home_services_subtitle_database : "Design, optimize, and manage databases to store and retrieve data efficiently, ensuring data integrity and security.",
//    home_services_title_analytics : "Data Analytics",
//    home_services_subtitle_analytics : "Extract valuable insights from your data with our advanced analytics solutions, enabling data-driven decision-making.",
//    home_services_title_uiuxdesign : "UI/UX Design",
//    home_services_subtitle_uiuxdesign : "Craft visually stunning and user-friendly UI/UX designs that captivate users and enhance app usability.",
//    home_services_title_webapps : "Web Apps",
//    home_services_subtitle_webapps : "Develop dynamic and scalable web applications that cater to your specific business needs and deliver outstanding performance.",
//    home_services_title_saas : "SaaS",
//    home_services_subtitle_saas : "Transform your software into a Software-as-a-Service (SaaS) model, enabling scalable and accessible solutions for your customers.",

//    home_contact_area_title_mini:"Explore our dynamic team for endless possibilities!",
//    home_contact_area_title_big:"Do you have any question? Feel free to contact us",

//    home_brands_customers_title_mini:"Our Customers",
//    home_brands_customers_title_big:"Join 100+ satisfied clients who've benefited from our mobile app expertise.",
//    home_brands_customers_subtitle:"From crafting simple icons to developing comprehensive websites and applications, we bring your ideas to life.",
   
//    home_contact_title:"Get in touch",
//    home_contact_address:"Address",
//    home_contact_email:"Email Address",


//    home_footer_title_company:"Company",
//    home_footer_company_about_us:"About Us",
//    home_footer_company_services:"Services",
//    home_footer_company_consulting:"Consulting",
//    home_footer_company_blog:"Blog",
//    home_footer_company_contactus:"Contact Us",
//    home_footer_title_resources:"Resources",
//    home_footer_resources_customers:"Customers",
//    home_footer_resources_pricing:"Pricing",
//    home_footer_resources_news:"News",
//    home_footer_resources_support:"Support",
//    home_footer_title_usecases:"Use Cases",
//    home_footer_usecases_uiuxdesign :"UI/UX Design",
//    home_footer_usecases_mobileapp :"Mobile App Development",
//    home_footer_usecases_apiintegration :"API Integration",
//    home_footer_usecases_database :"Database",
//    home_footer_usecases_software :"Software As A Service",
//    home_footer_usecases_analytics :"Data Analytics & Business Intelligence",
//    home_footer_title_information:"Information",


//   /// ABOUT US PAGE AND COMPONENTS
//    about_us_two_faq_title_mini: "Insights",
//    about_us_two_faq_title_big_one: "Tech Expertise ",
//    about_us_two_faq_title_big_two: " for Innovation",
//    about_us_two_faq_function_one_title: "Simplifying Complex Concepts",
//    about_us_two_faq_function_one_content: "Demystifying intricate tech concepts with clear explanations, because innovation doesn't have to be perplexing.",
//    about_us_two_faq_function_two_title: "Amplified Digital Performance",
//    about_us_two_faq_function_two_content: "Enhancing digital platforms for optimal speed, efficiency, and user satisfaction, because tech excellence matters.",
//    about_us_two_faq_function_three_title: "Integrated Solutions",
//    about_us_two_faq_function_three_content: "Seamlessly connecting diverse systems and tools to maximize efficiency and collaboration, because integration drives progress.",

//    about_us_two_achievement_title_mini: "Accomplishments",
//    about_us_two_achievement_title_big: "Empowering with Technical Excellence",
//    about_us_two_achievement_subtitle: "Unleash innovation and mastery with us. We take it to the next level, leaving a trail of successful projects and satisfied clients.",
//    about_us_two_achievement_function_one: "Streamlined Integrations",
//    about_us_two_achievement_function_two: "Innovative Mobile Apps Solutions",
//    about_us_two_achievement_discover_more: "Discover More",



//   /// SERVICES PAGE AND COMPONENTS
//    services_three_second_services_title: "These Are Our Expertise Areas",
//    services_three_second_services_subtitle: "We help you weather today's uncertainty through our best team.",

//    services_three_second_services_section_one_title_superior: "Mobile Apps",
//    services_three_second_services_section_one_title: "Mobile Apps",
//    services_three_second_services_section_one_subtitle: "Transforming ideas into interactive and user-centric mobile applications. Crafting engaging experiences for iOS and Android platforms, catering to diverse user needs and preferences. Seamlessly blending functionality, aesthetics, and performance to create apps that stand out.",
//    services_three_second_services_section_one_power_phrase: "Sculpting Immersive App Experiences",

//    services_three_second_services_section_two_title_superior: "Backend & Integrations",
//    services_three_second_services_section_two_title: "Backend & Integrations",
//    services_three_second_services_section_two_subtitle: "Building robust and scalable backend systems that power your applications. Expertly handling data management, user authentication, and seamless integrations with third-party services. Ensuring smooth communication between front-end interfaces and back-end functionality for a holistic user experience.",
//    services_three_second_services_section_two_power_phrase: "Architecting Cohesive Backend Landscapes",

//    services_three_second_services_section_three_title_superior: "Tech Consulting",
//    services_three_second_services_section_three_title: "Tech Consulting",
//    services_three_second_services_section_three_subtitle: "Guiding your business through the ever-evolving tech landscape. Providing strategic insights and tailored recommendations to optimize your technology infrastructure and operations. From identifying opportunities for digital transformation to overcoming technical challenges, our consulting services pave the way for informed decision-making.",
//    services_three_second_services_section_three_power_phrase: "Empowering Business through Tech Expertise",

//    services_three_second_services_section_four_title_superior: "Design UI/UX",
//    services_three_second_services_section_four_title: "Design UI/UX",
//    services_three_second_services_section_four_subtitle: "Elevate your online presence with our specialized UI/UX design services. We blend creativity, user-centered design, and technical expertise to create interfaces that captivate and engage. Whether you're building a website, app, or platform, our design solutions ensure seamless navigation, stunning aesthetics, and exceptional user satisfaction.",
//    services_three_second_services_section_four_power_phrase: "Crafting Digital Experiences that Resonate",

//    services_three_second_services_get_started_button: "Get Started",



//   /// BLOG PAGE AND COMPONENTS
//    blog_newsletter_title:"Newsletter",
//    blog_newsletter_subtitle:"Subscribe to our newsletter today to receive updates on the latest news",
//    blog_newsletter_newsletter_language:"Newsletter language",
//    blog_newsletter_policy_one:"I accept the",
//    blog_newsletter_policy_two:" Privacy Policy",
//    blog_newsletter_email_adress_input:"Email Address",
//    blog_newsletter_subscribe_button:"Subscribe",

//    blog_recent_news_title:"Recent News",
//    blog_categories_title:"Categories",
//    blog_recent_comments_title:"Recent Comments",
//    blog_recent_comments_empty_title:"There are no comments",
//    blog_recent_comments_empty_subtitle:"Be the first to leave one!",
//    blog_read_more_card:"Read More",
//    blog_comments_card:"Comments",

//    blog_comments_reply:"Leave a Reply",
//    blog_comments_email_not_published:"Your email address will not be a published",
//    blog_comments_consent:"I consent to my name and email being stored and used to send me updates and news.",

//    blog_details_article_category:"Category:   ",
//    blog_details_article_share:"Share:   ",
//    blog_details_article_author:"Author",
//    blog_details_article_last_comments:"Last comments:   ",
   

//    /// GENERIC MESSAGES
//    generic_your_message:"Your message...",
//    generic_your_name:"Your Name",
//    generic_your_email:"Your Email",
//    generic_send_message:"Send Message",

//    generic_newsletter_toast_error_politics:"You must accept the privacy policy to subscribe.",
//    generic_newsletter_toast_error_select_language:"Please select a language.",
//    generic_toast_success_send_message:"Message sent succesfully",
//    generic_toast_success_send_message_moderation:"Your message has been successfully submitted. It will be reviewed by our moderators before being published. Thank you for your contribution!",
//    generic_toast_error_notsend_message:"Could not send the message",

//   }
// },


