import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeCapabilities from '../Home/HomeCapabilities/HomeCapabilities';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import HomeServices from '../Home/HomeServices/HomeServices';
import HomeThreeSecondServices from '../HomeThree/HomeThreeSecondServices/HomeThreeSecondServices';
import { useTranslation } from "react-i18next";
import ServicesDetailsArea from './ServicesDetailsArea/ServicesDetailsArea';
import HomeBrands from '../Home/HomeBrands/HomeBrands';
import IntegrationsArea from './Integrations/IntegrationsArea';


const Services = () => {
  const { t } = useTranslation();

   return (
      <>
         <PageHelmet pageTitle="Services Page" />

         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_services")} subtitle={t("page_title_services")} />


         {/* STATIC INFO */}
         <IntegrationsArea/>



         <ServicesDetailsArea />
         <HomeCapabilities/>


         <HomeBrands />
         {/* <HomeServices /> */}
         {/* <HomeThreeSecondServices /> */}
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default Services;