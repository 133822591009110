import React from 'react';
import { Link } from 'react-router-dom';

const CommonPageHeader = ({ title, subtitle}) => {
   return (
      <>
         <section className="page__title p-relative d-flex align-items-center fix" style={{ backgroundColor: "#7c74ff", backgroundPosition: 'center', backgroundSize: 'cover'}}>
            <div className="slider__shape">
               <img className= "shape triangle"  src="../assets/img/icon/slider/triangle.png" alt="triangle" />
               <img className="shape dotted-square" src="../assets/img/icon/slider/dotted-square.png" alt="dotted-square" />
               <img className="shape solid-square" src="../assets/img/icon/slider/solid-square.png" alt="solid-square" />
               <img className="shape circle-2" src="../assets/img/icon/slider/circle2.svg" alt="circle" />
            </div>


            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-12">
                  <div className="page__title-content mt-100" style={{ position: 'relative', textAlign: 'left' }}>
                     <img src="../assets/img/icon/slider/circle-fluor.svg" alt="circle-fluor" style={{ 
                        position: 'absolute', 
                        left: '11%', 
                        top: '55%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '0',
                        width: '100%',
                        maxWidth: '650px', 
                        height: 'auto' }} />
                     
                     <div style={{ position: 'relative', zIndex: '1' }}>
                        <h2 style={{ color: '#fff' }}>{title}</h2>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff' }}>{subtitle}</li>
                        </ol>
                        </nav>
                     </div>
                  </div>
                  </div>
               </div>
            </div>


            {/* <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page__title-content mt-100">
                        <h2>{title}</h2>
                        <img src="../assets/img/icon/slider/circle-fluor.svg" alt="triangle" />

                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                              <li className="breadcrumb-item active" aria-current="page">{subtitle}</li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </div> */}

         </section>
      </>
   );
};

export default CommonPageHeader;