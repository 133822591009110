import React from 'react';
import SingleIntegration from './SingleIntegration';
import LoaderSpiner from '../../../components/LoadersSpiner/loader';


const integrations = [
   {
     integration_name: "Automatiza tu Marketing",
     description: "Crea y programa campañas de marketing en tus redes sociales automáticamente. Ahorra tiempo y maximiza tu presencia online con contenido optimizado para cada plataforma."
   },
   {
     integration_name: "Decisiones Basadas en Datos",
     description: "Obtén informes automáticos de analítica web y redes sociales. Toma decisiones informadas con datos consolidados de tus plataformas de SEO, Google Analytics y redes sociales."
   },
   {
     integration_name: "Organiza tus Documentos en Google Drive",
     description: "Clasifica automáticamente tus facturas, contratos y archivos importantes en Google Drive. Mantén todo ordenado y accesible con la ayuda de IA."
   },
   {
     integration_name: "Gestión Automatizada de Facturas",
     description: "Recibe, organiza y guarda tus facturas automáticamente en tu Google Drive o Dropbox. Evita pérdidas de documentos y mantén tus finanzas en orden."
   },
   {
     integration_name: "CRM Inteligente para Ventas",
     description: "Centraliza la información de tus clientes, realiza seguimientos automáticos y aumenta tus ventas con recordatorios y análisis predictivos."
   },
   {
     integration_name: "Automatiza tu Gestión de Recursos Humanos",
     description: "Simplifica la contratación y onboarding de empleados. Envía contratos, recopila documentos y gestiona tareas administrativas automáticamente."
   },
   {
     integration_name: "Optimiza tu SEO",
     description: "Realiza auditorías SEO automáticas y recibe recomendaciones para mejorar tu posicionamiento en buscadores. Monitorea tus rankings y analiza palabras clave sin esfuerzo."
   },
   {
     integration_name: "Automatiza tus Reportes de Ventas",
     description: "Recibe reportes automáticos de tus ventas diarias, semanales y mensuales. Analiza el rendimiento de tu negocio sin necesidad de revisar múltiples plataformas."
   },
   {
     integration_name: "Gestión de Redes Sociales",
     description: "Conecta tus cuentas de redes sociales y programa publicaciones en todas las plataformas desde un solo lugar. Monitorea comentarios y mensajes automáticamente."
   },
   {
     integration_name: "Simplifica tu Análisis Financiero",
     description: "Conecta tus cuentas bancarias y recibe un análisis automático de tus ingresos y gastos. Obtén alertas de movimientos importantes y predicciones de flujo de caja."
   },
   {
     integration_name: "Automatiza tu Estrategia de Email Marketing",
     description: "Crea campañas de email marketing automatizadas. Segmenta tu lista de contactos y envía correos personalizados sin necesidad de intervención manual."
   },
   {
     integration_name: "Automatiza la Gestión de Proyectos",
     description: "Integra tus herramientas de gestión de proyectos como Trello, Asana o Monday. Recibe notificaciones automáticas y actualiza tareas sin cambiar de aplicación."
   }
 ];



 const integrations2 = [
   {
     integration_name: "Automate Your Marketing",
     description: "Create and schedule marketing campaigns across your social media automatically. Save time and boost your online presence with optimized content for each platform."
   },
   {
     integration_name: "Data-Driven Decisions",
     description: "Get automated reports from web analytics and social media. Make informed decisions with consolidated data from SEO platforms, Google Analytics, and social networks."
   },
   {
     integration_name: "Organize Your Documents in Google Drive",
     description: "Automatically sort your invoices, contracts, and important files in Google Drive. Keep everything organized and easily accessible with AI assistance."
   },
   {
     integration_name: "Automated Invoice Management",
     description: "Receive, organize, and save your invoices automatically in Google Drive or Dropbox. Avoid losing documents and keep your finances in check effortlessly."
   },
   {
     integration_name: "Smart CRM for Sales",
     description: "Centralize your customer information, automate follow-ups, and increase sales with reminders and predictive analytics."
   },
   {
     integration_name: "Automate Your HR Management",
     description: "Simplify employee hiring and onboarding. Automatically send contracts, collect documents, and manage administrative tasks."
   },
   {
     integration_name: "Optimize Your SEO",
     description: "Run automatic SEO audits and get recommendations to improve your search engine rankings. Monitor your rankings and analyze keywords without any manual effort."
   },
   {
     integration_name: "Automate Sales Reports",
     description: "Receive daily, weekly, and monthly sales reports automatically. Analyze your business performance without having to check multiple platforms."
   },
   {
     integration_name: "Social Media Management",
     description: "Connect your social media accounts and schedule posts across all platforms from one place. Automatically monitor comments and messages."
   },
   {
     integration_name: "Simplify Financial Analysis",
     description: "Connect your bank accounts and get automatic analysis of your income and expenses. Receive alerts for important transactions and cash flow predictions."
   },
   {
     integration_name: "Automate Your Email Marketing Strategy",
     description: "Create automated email marketing campaigns. Segment your contact list and send personalized emails without manual intervention."
   },
   {
     integration_name: "Automate Project Management",
     description: "Integrate your project management tools like Trello, Asana, or Monday. Receive automatic notifications and update tasks without switching apps."
   }
 ];
 



const IntegrationsArea = () => {
   return (
      <>
         <section className="team__area pt-115 pb-110">
            <div className="container">
               <div className="row align-items-center mb-55">
                  <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8">
                     <div className="section__title section__title-3 mb-30">
                        <span>Integrations</span>
                        <h2>Simplifying your life</h2>

                     </div>
                  </div>
               </div>
               <div className="row">




               {integrations.length > 0 ? (

                  <div className='row'>

                  { integrations.map((item, i) => { 

                        return <SingleIntegration image={i+ 1} integration_name={item.integration_name} description={item.description} /> } ) }

                  </div>
                  ) : (
                  <LoaderSpiner />
                  )}




                  
                  {/* <SingleIntegration image="2" name="Norman Gordon" title="Associate Consultant" />
                  <SingleIntegration image="3" name="Indigo Violet" title="Project Manager" />
                  <SingleIntegration image="4" name="Dylan Meringue" title="Support" />
                  <SingleIntegration image="5" name="Berlin Corleone" title="App Developer" />
                  <SingleIntegration image="6" name="Shahnewaz Sakil" title="Web Developer" />
                  <SingleIntegration image="7" name="Chauffina Carr" title="UI/UX Designer" />
                  <SingleIntegration image="8" name="Justin Case" title="Sumo Founder" />
                  <SingleIntegration image="9" name="Joss Sticks" title="Support" />
                  <SingleIntegration image="10" name="Dianne Ameter" title="App Designer" />
                  <SingleIntegration image="11" name="Russell Sprout" title="Web Developer" />
                  <SingleIntegration image="12" name="Lurch Schpellchek" title="Digital Marketer" /> */}


               </div >
            </div >
         </section >
      </>
   );
};

export default IntegrationsArea;