import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeCapabilities from '../Home/HomeCapabilities/HomeCapabilities';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import HomeServices from '../Home/HomeServices/HomeServices';
import HomeThreeSecondServices from '../HomeThree/HomeThreeSecondServices/HomeThreeSecondServices';
import HomePricing from '../Home/HomePricing/HomePricing';

const Pricing = () => {
   return (
      <>
         <PageHelmet pageTitle="Pricing" />

         <HomeOneHeader/>
         <CommonPageHeader title="Pricing" subtitle="Pricing" />

         <HomePricing/>

         {/* <HomeServices />
         <HomeCapabilities/>
         <HomeThreeSecondServices /> */}
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default Pricing;