import React from "react";

import { Oval, ProgressBar } from "react-loader-spinner";
import { colors } from "../../lib/constants";


// https://mhnpd.github.io/react-loader-spinner/docs/components/oval

const size = 75;
const LoaderSpiner = () => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

    {/* <Oval
      height={size}
      width={size}
      color={colors.mainPurple}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={colors.mainGrey}
      strokeWidth={2}
      strokeWidthSecondary={1}
    /> */}

        <ProgressBar
        height={size}
        width={size}
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor = {colors.mainGrey}
        barColor = {colors.mainPurple}
        />

    <p style={{ marginLeft: "10px", marginTop: "10px", color: colors.mainPurple  }}>
        Loading...
    </p>
  </div>
);

export default LoaderSpiner;
