import React, { useEffect, useState, useContext } from 'react';
// import { FaSearch } from 'react-icons/fa';

import { constants } from '../../../lib/constants';
import LoaderSpiner from '../../../components/LoadersSpiner/loader';

import {ContainerLeftSideArticles, ContainerCategories, ContainerArticles, ContainerLeftSideComments } from './blogComponents';
import NewsletterForm from './newsletterForm';
import { useTranslation } from "react-i18next";

import { AppContext } from '../../../context/AllContext';

// import { Link } from "react-router-dom";




const BlogLeftSideBar = () => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);


    let getLastArticles = constants.getThreeArticles
    let getTags = constants.getTags
    let getLastComments = constants.getThreeComments

    const [lastarticles, setArticle] = useState([]);
    const [tags, setTags] = useState([]);
    const [lastcomments, setComments] = useState([]);

    useEffect(() => {
        fetch(getLastArticles+'?language='+language).then( res => res.json()).then(data => setArticle(data)).catch(e => console.log(e));
        fetch(getTags).then( res => res.json()).then(data => setTags(data)).catch(e => console.log(e));
        fetch(getLastComments).then( res => res.json()).then(data => setComments(data)).catch(e => console.log(e));
      }, [  ]);


    return (
        <>
            <div className="col-xl-4 col-lg-4">
                <div className="blog__sidebar">

                    {/* <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-content">
                            <div className="search">
                                <form action="#">
                                    <input type="text" placeholder="Search..." />
                                    <button type="button"><i > <FaSearch /> </i></button>
                                </form>
                            </div>
                        </div>
                    </div> */}




                    <NewsletterForm/>





                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>{t("blog_recent_news_title")}</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    { lastarticles.length>0 ? <ContainerLeftSideArticles data={lastarticles} /> : <LoaderSpiner/> }
                                </ul>
                            </div>
                        </div>
                    </div>





                    {/* FIRST STYLE - categories*/}
                    {/* <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Categories</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="cat-link">
                                <ul>
                                    { categories.length>0 ? <ContainerCategories data={categories} /> : <LoaderSpiner/> }
                                </ul>
                            </div>
                        </div>
                    </div> */}


                    {/* SECOND STYLE - Previous popular tags*/}
                    <div className="sidebar__widget mb-50 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>{t("blog_categories_title")}</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="tags">
                            { tags.length>0 ? <ContainerCategories data={tags} /> : <LoaderSpiner/> }
                            </div>
                        </div>
                    </div>







                    <div className="sidebar__widget mb-60 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>{t("blog_recent_comments_title")}</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc__comments">
                                <ul>


                                { lastcomments.length === 0 ? (
                                    <div className="rc__comments-content">
                                        <h6>{t("blog_recent_comments_empty_title")}</h6>
                                        <p>{t("blog_recent_comments_empty_subtitle")}</p>
                                    </div>
                                    ) : (
                                    lastcomments.length > 0 ? (
                                        <ContainerLeftSideComments data={lastcomments} />
                                        ) : (
                                            <LoaderSpiner />
                                            )
                                        )
                                    }

      



                                    {/* <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{marginTop:'-2px'}} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Justin Case</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li>

                                    <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{marginTop:'-2px'}} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Eric Widget</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li>

                                    <li className="d-flex mb-25">
                                        <div className="rc__comments-icon mr-30">
                                            <i ><FaRegComment style={{marginTop:'-2px'}} /></i>
                                        </div>
                                        <div className="rc__comments-content">
                                            <h6>Penny Tool</h6>
                                            <p>My lady mush hanky panky young delinquent.!</p>
                                        </div>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                    </div>








                </div>
            </div>
        </>
    );
};

export default BlogLeftSideBar;