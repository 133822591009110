import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';

import { businessLogic } from '../../../lib/constants';
import { useTranslation } from "react-i18next";



const HomeServices = () => {
   const { t } = useTranslation();

   return (
      <>
         <section className="services__area home_slider_6 pt-100 pb-100" id='services'

         >
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>{t("home_services_title_mini")}</span>
                        <h2>{t("home_services_title_big")}</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService destiny={businessLogic.mobile_landing} icon="1" title={t("home_services_title_mobileapps")} description={t("home_services_subtitle_mobileapps")} />
                  <SingleService destiny={businessLogic.backend_landing} icon="2" title={t("home_services_title_backend")}  description={t("home_services_subtitle_backend")} />
                  <SingleService destiny={businessLogic.consulting_landing} icon="3" title={t("home_services_title_consulting")}  description={t("home_services_subtitle_consulting")} />
                  <SingleService destiny={businessLogic.database_landing} icon="8" title={t("home_services_title_database")}  description={t("home_services_subtitle_database")} />

                  <SingleService destiny={businessLogic.data_landing} icon="4" title={t("home_services_title_analytics")}  description={t("home_services_subtitle_analytics")} />
                  <SingleService destiny={businessLogic.uiux_landing} icon="5" title={t("home_services_title_uiuxdesign")}  description={t("home_services_subtitle_uiuxdesign")} />
                  <SingleService destiny={businessLogic.webapps_landing} icon="6" title={t("home_services_title_webapps")}  description={t("home_services_subtitle_webapps")} />
                  <SingleService destiny={businessLogic.saas_landing} icon="7" title={t("home_services_title_saas")}  description={t("home_services_subtitle_saas")}/>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
