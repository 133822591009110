import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";



const CommonCtaArea = () => {
   const { t } = useTranslation();

   return (
      <>

{/* <div className={`slider__height d-flex align-items-center`}
    style={{
      backgroundImage: "url('/assets/img/slider/slider-5.jpg')",
      // backgroundImage: "url('../img/slider/slider-5.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
  }}> */}
   
   {/* <div className={`slider__height d-flex align-items-center home_slider_5`}> */}

      
         {/* <section className="cta__area pt-180 pb-155 home_slider_5"  */}
         {/* > */}

         {/* <section className="cta__area pt-180 pb-155" style={{ background: `url(assets/img/cta/cta-bg.jpg)` ,backgroundPosition: 'center', backgroundSize: 'cover' }} > */}
         <section className="cta__area pt-50 pb-80" style={{ backgroundColor: '#7c74ff' ,backgroundPosition: 'center', backgroundSize: 'cover' }} >
            
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center pt-100">
                     {/* <img src="assets/img/cta/sparkle.svg" alt="Sparkle"/> */}

                        <span >{t("home_contact_area_title_mini")}</span>
                        <h1>{t("home_contact_area_title_big")}</h1>

                        {/* <div style={{ margin: '10px auto' }}>  */}
                           {/* <img src="assets/img/cta/arrow.svg" alt="theKey"/> */}
                        {/* </div> */}

                        <div className="cta__btn">
                        <img src="assets/img/cta/arrow.svg" alt="theKey"/>
                        <br></br>
                        <br></br>
                        
                           
                           <Link to="/contact" className="z-btn z-btn-white mb-30">{t("contact_us")}</Link>
                           {/* <HLink smooth to="/contact" className="z-btn z-btn-white mb-30">Contact Us</HLink> */}
                           {/* <Link to="/contact" className="z-btn z-btn-transparent mb-30" style={{ backgroundColor: '#25D366' }} >WhatsApp</Link>    */}
                           {/* <Link to="/contact" className="z-btn z-btn-transparent mb-30">Work with us</Link> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* </div> */}
         </section>
      </>
   );
};

export default CommonCtaArea;