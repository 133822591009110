import React, { useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";

import BlogSingleItem from "../../../components/BlogSingleItem/BlogSingleItem";
import { FaFacebookF,FaRegComment, FaSearch } from 'react-icons/fa';

import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';
// import { BiReply } from 'react-icons/bi';
import BlogSingleComment from "../../BlogDetails/BlogDetailsArea/BlogSingleComment";
import FormCommentBlog from "../../BlogDetails/BlogDetailsArea/FormComment";

import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon,TelegramShareButton, TelegramIcon, LinkedinIcon, LinkedinShareButton } from 'react-share';
import LoaderSpiner from '../../../components/LoadersSpiner/loader';

import { constants } from "../../../lib/constants";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../context/AllContext";



// BLOG PAGE SINGLE ARTICLE MINIMIZED
export function ContainerArticles({ data }) {
  const dataArray = data.map((article) => {
    return article.quote ? (
      <div className="blog__quote mb-50 p-relative white-bg fix" key={article.UUID} >
        <div className="blog__quote-line">
          <img
            className="line-1"
            src="../assets/img/icon/blog/line-1.png"
            alt=""
          />
          <img
            className="line-2"
            src="../assets/img/icon/blog/line-2.png"
            alt=""
          />
        </div>
        <div className="quote mb-10">
          <img src="../assets/img/icon/blog/quote-1.png" alt="" />
        </div>
        <div className="quote-2 p-absolute">
          <img src="../assets/img/icon/blog/quote-2.png" alt="" />
        </div>

        <blockquote>
            <p>{article.html_content}</p>
        </blockquote>

        <h4>{article.author}</h4>
      </div>
    ) : (
      <BlogSingleItem
         key={article.UUID}
        image="2"
        user_image="1"
        name={article.author}
        title={article.title}
        article={article}
        date={article.createdAt.split("T")[0]}
      />
    );

    // <li className="d-flex mb-20" key={article.UUID}>
    //     <div className="rc-thumb mr-15">
    //         <Link to="/blogDetails"><img src={`../assets/img/blog/rc/rc-${article.image}.jpg`}
    //             alt="rc-post" /></Link>
    //     </div>
    //     <div className="rc-text">
    //         <h6><Link to="#">{article.title}</Link>
    //         </h6>
    //         <div className="rc-meta"><span>Jan 02, 2022</span> </div>
    //     </div>
    // </li>
  });

  return <div>{dataArray}</div>;
}




// LAST THREE ARTICLES COMPONENT SIDEBAR
export function ContainerLeftSideArticles({ data }) {
  const dataArray = data.map((article) => {
    return (
      <div key={article.UUID}>
      <li className="d-flex mb-20">
        <div className="rc-thumb mr-15">
          {/* <Link to="/blogDetails"> */}
            <img
            //   src={`../assets/img/blog/rc/rc-${article.image}.jpg`}
              src={`../assets/img/blog/author/InvokeApp.svg`}
              alt="rc-post"
              height={40}
              width={40}
            />
          {/* </Link> */}
        </div>
        <div className="rc-text">
          <h6>
            {/* <Link to="/blogDetails"> */}
            
            <Link to={`/blog-details/${article.link}`}>{article.title}</Link>

          </h6>
          <div className="rc-meta">
            <span>{article.createdAt.split("T")[0]}</span>{" "}
          </div>
        </div>
      </li>
      </div>
    );
  });
  return <div>{dataArray}</div>;
}




// LAST COMMENTS COMPONENT SIDEBAR
export function ContainerLeftSideComments({ data }) {
   const MAX_LENGTH = 60;

   const dataArray = data.map((comment) => {
     return (


      <li className="d-flex mb-25" key={comment.UUID}>
         <div className="rc__comments-icon mr-30">
            <i ><FaRegComment style={{marginTop:'-2px'}} /></i>
         </div>
         <div className="rc__comments-content">
            <h6>{comment.name}</h6>

            {comment.comment.length > MAX_LENGTH ?
                  (
                     <div>
                        {`${comment.comment.substring(0, MAX_LENGTH)}    ...     `}
                     </div>
                  ) :
                  <p>{comment.comment}</p>
               }

         </div>
   </li>

      //  <li className="d-flex mb-20" key={article.UUID}>
      //    <div className="rc-thumb mr-15">
      //      <Link to="/blogDetails">
      //        <img
      //          src={`../assets/img/blog/rc/rc-${article.image}.jpg`}
      //          alt="rc-post"
      //        />
      //      </Link>
      //    </div>
      //    <div className="rc-text">
      //      <h6>
      //        <Link to="#">{article.title}</Link>
      //      </h6>
      //      <div className="rc-meta">
      //        <span>{article.createdAt.split("T")[0]}</span>{" "}
      //      </div>
      //    </div>
      //  </li>
      
     );
   });
   return <div>{dataArray}</div>;
}




// CATEGORIES SIDEBAR SINGLE COMPONENT
export function ContainerCategories({ data }) {

  const dataArray = data.map((tag) => {
    return (

    /// SECOND STYLE - Previous popular tags
    <Link key={tag.UUID} to="#">{tag.esp_display_name} ({tag.number_of_articles})</Link>

    /// FIRST STYLE
    //   <li>
    //     <Link to="/blogDetails">
    //       {" "}
    //       {category.name} ({category.number_of_articles})
    //     </Link>
    //   </li>

    );
  });
  return <div>{dataArray}</div>;
}




// THIS IS USED IN BLOG DETAILS PAGE
export function ContainerSingleArticle({array}) {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);


  const data = array.article
  
//   const dataArray = array.article.map((data) => {
    return (
      <>
         <section className="blog__area pt-120 pb-120" key={data.uuid}>
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        
                        <div className="blog__text mb-40">
                           <h3>{data.title}</h3>
                           <p className="pt-40">                        
                              <div dangerouslySetInnerHTML={{__html: `${data.html_content}` }}></div>
                           </p>
                        </div>


                        <div className="blog__tag mb-30">
                           <span>{t("blog_details_article_category")}   </span>



                           {array.tags.length > 0 ? (
                              array.tags.map((tag, index) => (
                                 <React.Fragment key={index}>
                                    {/* <a>{tag.esp_display_name}</a> */}
                                    <a>
                                       {language === 'es' ? tag.esp_display_name : tag.eng_display_name}
                                    </a>                                 </React.Fragment>
                                    ))
                                    ) : (
                                    <div className="pt-200 pb-200">
                                       <LoaderSpiner />
                                    </div>
                                  )}

                           {/* <a>Blog</a> */}
                           {/* <a href="#">Creative</a> */}
                           {/* <a href="#">Portfolio</a> */}
                           {/* <a href="#">Theme</a> */}

                        </div>
                        
                        
                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>{t("blog_details_article_share")} </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>

                                 <li>
                                 <FacebookShareButton
                                    url={`https://invokeapp.io/blog-details/${data.link}`}
                                    quote={`invokeapp | ${data.title}`}
                                    hashtag="#invokeapp">
                                    <FacebookIcon size={32} borderRadius={12} />
                                 </FacebookShareButton>
                                 </li>
                                                                        
                                 <li>
                                 <WhatsappShareButton
                                    url={`https://invokeapp.io/blog-details/${data.link}`}
                                    quote={`invokeapp | ${data.title}`}
                                    hashtag="#invokeapp">
                                    <WhatsappIcon size={32} borderRadius={12} />
                                 </WhatsappShareButton>
                                 </li>

                                 <li>
                                 <LinkedinShareButton
                                    url={`https://invokeapp.io/blog-details/${data.link}`}
                                    quote={`invokeapp | ${data.title}`}
                                    hashtag="#invokeapp">
                                    <LinkedinIcon size={32} borderRadius={12} />
                                 </LinkedinShareButton>
                                 </li>

                                 <li>
                                 <TelegramShareButton
                                    url={`https://invokeapp.io/blog-details/${data.link}`}
                                    quote={`invokeapp | ${data.title}`}
                                    hashtag="#invokeapp">
                                    <TelegramIcon size={32} borderRadius={12} />
                                 </TelegramShareButton>
                                 </li>

                                 {/* <li>
                                    <a href="https://www.example.com">
                                       <i ><FaFacebookF /></i>
                                       <i ><FaFacebookF /></i>
                                    </a>
                                 </li>

                                 <li>
                                    <a href="#">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>


                                 <li>
                                    <a href="#">
                                       <i ><FaVimeoV /> </i>
                                       <i ><FaVimeoV /> </i>
                                    </a>
                                 </li> */}


                              </ul>
                           </div>
                        </div>

                        <div className="blog__author mb-95 d-sm-flex">
                           <div className="blog__author-img mr-30"> 
                              {data.author === "invokeapp" ? <img src="../assets/img/blog/author/InvokeApp.svg" alt="" height={65}/> : <img src="../assets/img/blog/author/author-1.jpg" alt="" />}
                              {/* <img src="../assets/img/blog/author/author-1.jpg" alt="" /> */}
                           </div>
                           <div className="blog__author-content">
                              <h5>{data.author}</h5>
                              <span>{t("blog_details_article_author")}</span>
                              {/* <p>I said cracking goal down the pub blag cheeky bugger at public school A bit of how's your father boot.!</p> */}
                           </div>
                        </div>



                        <div className="post-comments mb-95" data-wow-delay=".6s">
                           <div className="post-comment-title mb-40">
                              {/* <h3>{data.comments.length} Comments</h3> */}
                              <h3>{t("blog_details_article_last_comments")}</h3>
                           </div>

                           <div className="latest-comments">

                              {/* <BlogSingleComment comments={array.comments} /> */}
                                 
                           </div>



                        </div>

                        <div className="post-comment-form">
                           <h4>{t("blog_comments_reply")} </h4>
                           <span>{t("blog_comments_email_not_published")}</span>
                           <FormCommentBlog uuid={data.UUID}/>
                        </div>

                     </div>
                  </div>

                  <BlogLeftSideBar/>
               </div>
            </div>
         </section>
      </>
    );
//   }
// );

//   return <div>{dataArray}</div>;


}



