import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";


// import { withRouter } from 'react-router-dom';




const BlogDetails = (props) => {
  const { t } = useTranslation();

   const params= useParams()

   return (
      <>
         <PageHelmet pageTitle="Blog Details" />
         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_blog_details")} subtitle={t("page_title_blog_details")} />
         <BlogDetailsArea params={params.id} />
         <BlogFooter/>
      </>
   );
};

export default BlogDetails;