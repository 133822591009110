import React from "react";
import { useTranslation } from "react-i18next";
// import { FaFacebookF, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { Link } from "react-router-dom";

const SingleIntegration = ({ image, integration_name, description }) => {
  const { t } = useTranslation();

  const MAX_LENGTH = 50;
  const MAX_LENGTH_2 = 30;

  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6">
        <Link to="/integration-details/">
          <div className="team__item p-relative text-center fix mb-30">
            <div className="team__thumb mb-30">


              {/* <img
                src={`assets/img/team/team-${image}.jpg`}
                alt="integration service"
              /> */}

              <div className="team__info text-start">
                {/* <h3><Link to="/integration-details/">{name}</Link></h3> */}
                <h3>{integration_name}</h3>
                <span>{description}</span>
              </div>

              {/* <div className="team__social theme-social">
                        <ul>
                           <li>
                              <a href="#">
                                 <i > <FaFacebookF/> </i>
                                 <i > <FaFacebookF /> </i>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <i > <FaTwitter/> </i>
                                 <i > <FaTwitter /> </i>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <i > <FaVimeoV/> </i>
                                 <i > <FaVimeoV/> </i>
                              </a>
                           </li>
                        </ul>
                     </div> */}
            </div>

            <div className="team__content">



              {/* <h3>{integration_name}</h3> */}


              {integration_name.length > MAX_LENGTH_2 ? (
                <h3>{integration_name.substring(0, MAX_LENGTH_2)} ...</h3>
              ) : (
                <h3>{integration_name}</h3>
              )}



              {description.length > MAX_LENGTH ? (
                <span>{description.substring(0, MAX_LENGTH)} ...</span>
              ) : (
                <span>{description}</span>
              )}



            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SingleIntegration;
