import React, { useState } from "react";

const Paginate = ({
  postsPerPage,
  totalPosts,
  paginate,
  previousPage,
  nextPage,
  currentPage
}) => {
  const [selectedPage, setSelectedPage] = useState(1); // State to track selected page


  console.log(previousPage)
  console.log(nextPage)

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (number) => {
    setSelectedPage(number); // Update selected page when clicked
    paginate(number); // Call the paginate function
  };

  return (
    <>
      {pageNumbers.map((number) => (
        <li
          key={number}
          onClick={() => handlePageClick(number)}
          className={selectedPage === number && selectedPage === currentPage ? "active" : ""} 
        >
          <a href="#0">
            <span>{number}</span>
            <span>{number}</span>
          </a>
        </li>
      ))}
    </>
  );
};

export default Paginate;
