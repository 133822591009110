import React, { useState } from "react";

import { constants } from "../../lib/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

// import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

let endpointContact = constants.contactUs;

export const PostContactUs = () => {
  const { t } = useTranslation();

  // const gaEventTracker = useAnalyticsEventTracker("Contact us");

  const [postId, setPostId] = useState(null);
  const [name, setName] = useState();
  const [message, setMessage] = useState();
  const [email, setEmail] = useState();
  const [politics, setPolitics] = useState(false);


  const resetForm = () => {
    setName("");
    setMessage("");
    setEmail("");
  };

  const handleButtonClick = () => {
    // gaEventTracker("PostContactUs");

    if (!politics) {
      toast.error(t("generic_newsletter_toast_error_politics"));
      return;
  }

    // const name = document.querySelector('input[name="name"]').value;
    // const email = document.querySelector('input[name="email"]').value;
    // const message = document.querySelector('textarea[name="message"]').value;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    };
    fetch(endpointContact, requestOptions)
      .then((response) => {
        console.log(response.status);
        if (response.status === 201) {
          setPostId(response.status);
          toast.success(t("generic_toast_success_send_message"));
          resetForm();
        } else {
          setPostId(null); 
          toast.error(t("generic_toast_error_notsend_message"));
        }
      })
      .catch((error) => {
        setPostId(null); 
      });
  };

  return (
    <div className="contact__form">
      <form>
        <input
          type="text"
          name="name"
          placeholder={t("generic_your_name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder={t("generic_your_email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          name="message"
          placeholder={t("generic_your_message")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ lineHeight: "1.5" }}
        ></textarea>

        
        <div className="checkbox-container" style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" id="acceptPolicy" name="acceptPolicy" checked={politics} onChange={(e) => setPolitics(e.target.checked)} style={{ width: '20px', height: '20px', cursor: 'pointer' }} required />
            <label >&nbsp;&nbsp;&nbsp;{t("blog_newsletter_policy_one")}<a href="/contact-policies">{t("blog_newsletter_policy_two")}</a></label>
        </div>
        <br/>


        <button type="button" className="z-btn" onClick={handleButtonClick}>{t("generic_send_message")}</button>
        
        
        {/* onClick={() => resetForm()} */}
      </form>

      {/* <div className="contact__info mt-20">
        <ul>
          <li>
            <h4>WhatsApp</h4>
            <div onClick={() => gaEventTracker("WhatsApp-HomePage")}>
              <Link
                to=""
                className="z-btn z-btn-transparent mt-10"
                style={{ backgroundColor: "#25D366" }}
              >
                WhatsApp
              </Link>
            </div>
          </li>
        </ul>
      </div> */}

      <ToastContainer />
    </div>
  );
};

// export { PostContactUs };
