import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import BlogFooter from './BlogFooter/BlogFooter';
import BlogsArea from './BlogsArea/BlogsArea';
import { useTranslation } from "react-i18next";


const Blog = () => {
  const { t } = useTranslation();

   return (
      <>
         <PageHelmet pageTitle="Blog" />
         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_blog")} subtitle={t("page_title_blog")} />
         <BlogsArea/>
         <BlogFooter/>
         
      </>
   );
};

export default Blog;