import React, { useState, createContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";

// // i18n.js o un archivo similar
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';



export const AppContext = createContext();

const AllContext = ({ children }) => {
  const { i18n } = useTranslation();


   const [isVideoOpen, setIsVideoOpen] = useState(false);
   const [stickyMenu, setStickyMenu] = useState(false);
   const [language, setLanguage] = useState('es');

   // sticky
   useEffect(() => {
      const stickyMenuBar = () => {
         if (window.scrollY > 80) {
            setStickyMenu(true)
         }
         else {
            setStickyMenu(false)
         }
      }
      window.addEventListener('scroll', stickyMenuBar);
   }, [])


   const handleTrans = (code) => {
      i18n.changeLanguage(code);
      setLanguage(code);
    };
     

   const values = {
      isVideoOpen,
      setIsVideoOpen,
      stickyMenu,
      handleTrans,
      language,
   };



   return (
      <>
         <AppContext.Provider value={values}>
            {children}
         </AppContext.Provider>
      </>
   );
};

export default AllContext;