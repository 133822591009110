import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const HomeAbout = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about__area">
      {/* <section className="about__area pb-200 pt-100"> */}

      <div className={`single-slider slider__height d-flex align-items-center home_slider_3`}>


        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                {/* <img src="assets/img/about/about-1.jpg" alt="" /> */}
                <img src="assets/img/core/growthai.png" alt="growth ai, marketing integrations, tools" />
                {/* <div className="about__shape"> */}
                  {/* <img src="assets/img/about/red-shape.png" alt="" /> */}
                {/* </div> */}
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <span>{t("home_about_title_mini")}</span>
                  <h2>{t("home_about_title_big")}</h2>
                </div>
                <p>
                  {t("home_about_subtitle")}
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        {t("home_about_caption_one")}
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        {t("home_about_caption_two")}
                      </span>
                    </li>



                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        {t("home_about_caption_three")}
                      </span>
                    </li>

                  </ul>

                </div>
                <Link to="/services" className="z-btn z-btn-transparent">
                  {t("home_about_discover_services")}
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link>

                <br/>
                <br/>

                <a href="https://www.sortlist.com/agency/invoke-app-consulting-it-services-llc" target="blank">
                  <img src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-black-light-xl.svg" alt="flag"  height={60}/>
                </a>
                
                <br/>
                <br/>

                <a href="https://www.digitalocean.com/?refcode=98c9884b4714&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge" target="blank">
                  <img src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%201.svg" alt="DigitalOcean Referral Badge" height={60}/>
                </a>


                {/* <a href="https://www.sortlist.com/agency/invoke-app-consulting-it-services-llc" target="blank">
                  <img src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-white-light-xs.svg" alt="flag" height={60}  />
                </a> */}
                  

              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
