import React, { useEffect, useState } from "react";

import { constants } from "../../../lib/constants";

import { ContainerSingleArticle } from "../../../pages/Blogs/BlogsArea/blogComponents";
import LoaderSpiner from "../../../components/LoadersSpiner/loader";

const BlogDetailsArea = (params) => {

  let getUniqueArticle = constants.getUniqueArticle;

  const [data, setData] = useState(null);

  console.log(getUniqueArticle + params.params)



  useEffect(() => {
    fetch(getUniqueArticle + params.params)
      .then((res) => res.json() )
      .then((data) => setData(data))
      .catch((e) => console.log(e));
  });



  return (
    <>
       
      {data ? (
        <ContainerSingleArticle array={data} />
        // <div></div>
      ) : (
        <div className="pt-200 pb-200">
          <LoaderSpiner />
        </div>        
      )}

    </>
  );
};

export default BlogDetailsArea;
